import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";
import TopButton from "../../TopButton";
import SearchBar from "../../SearchBar";

const Wrap = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IngredientList = () => {
  const [list, setList] = useState([]);

  const {
    ingredientSearchableList,
    ingredientsData,
    onSearchBarTextChange,
    filterTextIngredientList,
    setFilterTextIngredientList,
    modifiedData,
  } = useOutletContext();

  useEffect(() => {
    if (ingredientSearchableList.length == 0) {
      setList(ingredientsData);
    } else {
      setList(ingredientSearchableList);
    }
  }, [ingredientSearchableList, ingredientsData, modifiedData]);

  return (
    <div style={{ marginTop: "180px" }}>
      <TopButton />
      <Wrap>
        <SearchBar
          filterText={filterTextIngredientList}
          list={ingredientsData}
          onSearchBarTextChange={onSearchBarTextChange}
          setFilterText={setFilterTextIngredientList}
        />
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nume</th>
              <th scope="col">Descriere</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item) => {
              return (
                <tr key={item.Id}>
                  <th scope="row">{item.Id}</th>
                  <td>{item.Name}</td>
                  <td>{item.Description}</td>

                  <td>
                    <Link
                      to={`/admin/sterge-ingredient/${item.Id}`}
                      className="btn btn-danger me-2"
                      style={{ width: "90px", marginBottom: "5px" }}
                    >
                      Șterge
                    </Link>
                    <Link
                      to={`/admin/editeaza-ingredient/${item.Id}`}
                      className="btn btn-warning me-2"
                      style={{ width: "90px" }}
                    >
                      Editează
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrap>
    </div>
  );
};

export default IngredientList;
