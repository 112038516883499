import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminMenu from "../AdminMenu";
import Select from "react-select";

const AddCategory = () => {
  const { modifiedData, setModifiedData, menuItems } = useOutletContext();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [selectedMenuItemIds, setSelectedMenuItemIds] = useState([]);

  //******************************Am retinut in selectedCategoriesIds id-urile categoriilor selectate************************************************* */
  //**************************************************************************************************************************************************** */

  //Formez un vector de tipul [{value:"",label:""}]
  const options = [];
  menuItems.forEach((item) => {
    const option = {
      value: `${item.Id}`,
      label: `${item.Name}`,
    };
    options.push(option);
  });
  // handle onChange event of the dropdown
  const handleMenuItemChange = (e) => {
    setSelectedMenuItemIds(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const category = {
      Name: name,
      Path: path,
      SelectedMenuItemIds: selectedMenuItemIds[0],
    };

    const config = {
      method: "POST",
      body: JSON.stringify(category),
    };

    //  Corectez in baza de date
    fetch("categoriesCRUD.php", config)
      //   .then((rezultat) => rezultat.text())
      //   .then((rez) => console.log(rez))

      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-categorii"));
  };
  return (
    <div style={{ marginTop: "180px" }}>
      <form
        style={{ width: "70%", margin: "auto", marginTop: "70px" }}
        onSubmit={handleSubmit}
      >
        <h3>Adaugă categorie</h3>
        <div className="mb-3 mt-3">
          <label className="form-label text-danger"> Denumire</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label className="form-label text-danger">
            Path - de tip "/path"
          </label>
          <input
            type="text"
            className="form-control"
            value={path}
            onChange={(event) => {
              setPath(event.target.value);
            }}
          />
        </div>
        {/* ************************************Select List pt Menu Item********************************* */}
        <div className="mb-3">
          <label className="form-label text-danger">
            Alegeti intrarea din meniu:
          </label>
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={options.filter((obj) =>
              selectedMenuItemIds.includes(obj.value)
            )} // set selected values
            options={options} // set list of the data
            onChange={handleMenuItemChange} // assign onChange function
            isMulti
            isClearable
          />
        </div>

        <button type="submit" className="btn btn-info w-25">
          <p>Adaugă</p>
        </button>
      </form>
    </div>
  );
};

export default AddCategory;
