import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Svg from "./Svg/productlistpage-svg.svg";

const WrapContainer = styled(Container)`
  width: 60%;
  margin-top: 180px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(243, 218, 214, 0.6);
  border-radius: 25px;
  padding: 3rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  @media screen and (max-width: 500px) {
    width: 80%;
  }
`;
const BackgroundMask = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -99;
  top: 110px;
  background-color: rgba(0, 0, 0, 0.4);
`;
const BackgroundImage = styled.div`
  background-image: url(${Svg});
  position: fixed;
  width: 100%;
  height: calc(100vh - 140px);
  z-index: -100;
  top: 110px;
`;
const ProductPageHeader = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const ProductImage = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const TitlePriceValabilityDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 18px;
  text-align: center;
  @media screen and (max-width: 500px) {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;
const PriceDiv = styled.div`
  color: rgba(143, 30, 53, 255);
  font-weight: 600;
`;
const ValabilityDiv = styled.div`
  font-weight: 600;
  text-align: center;
`;
const ProductDescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const Descriptions = styled.div`
  max-width: 100%;
`;

const Ingredients = styled.div`
  max-width: 100%;
`;

const style = {
  ProductImage: {
    width: "150px",
    height: "200px",
  },
};

const ProductPage = ({
  productsData,
  productDescriptionsData,
  product_ingredientData,
  ingredientsData,
  pricesbyquantityData,
  product_categoryData,
  categoryData,
}) => {
  const [product, setProduct] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [productDescriptions, setProductDescriptions] = useState([]);
  const [productIngredientsIds, setProductIngredientsIds] = useState([]);
  const [productIngredients, setProductIngredients] = useState([]);
  const [productPricesByQuantities, setProductPricesByQuantities] = useState(
    []
  );
  const [
    orderedPricesByQuantityAfterQuantity,
    setOrderedPricesByQuantityAfterQuantity,
  ] = useState([]);

  const params = useParams();
  const productId = parseInt(params.Id); //Gasim id-ul produsului curent

  useEffect(() => {
    const product = productsData.find((item) => {
      return parseInt(item.Id) == parseInt(productId);
    });
    setProduct(product);
  }, [productsData]);

  //--------Gasesc id-ul actegoriei curente si apoi gasesc categoria curenta

  useEffect(() => {
    if (product) {
      const currProduct_Category = product_categoryData.find((item) => {
        return item.Product_Id == product.Id;
      });
      console.log(currProduct_Category);
      if (currProduct_Category) {
        const currCategory = categoryData.find((item) => {
          return item.Id == currProduct_Category.Category_Id;
        });

        setCurrentCategory(currCategory);
      }
    }
  }, [product_categoryData, product, categoryData]);

  useEffect(() => {
    const prDescriptions = productDescriptionsData.filter((item) => {
      return parseInt(item.Product_Id) == parseInt(productId);
    });
    setProductDescriptions(prDescriptions);
  }, [productDescriptionsData]);

  useEffect(() => {
    const prIngredients = product_ingredientData
      .filter((item) => {
        return parseInt(item.Product_Id) == parseInt(productId);
      })
      .map((el) => {
        return el.Ingredient_Id;
      });
    setProductIngredientsIds(prIngredients);
  }, [product_ingredientData]);

  //--------Gasesc toate ingredientele cu id-urile in vectorul productIngredientsIds----------

  useEffect(() => {
    if (productIngredientsIds) {
      const prIngredients = ingredientsData.filter((item) => {
        return productIngredientsIds.includes(item.Id);
      });
      setProductIngredients(prIngredients);
    }
  }, [productIngredientsIds]);

  //-----------Selectez toate variantele de preturi si cantitati pt acest produs-----------
  //---------------------------------------------------------------------------------------
  useEffect(() => {
    const prPricesByQuantities = pricesbyquantityData.filter((item) => {
      return item.Product_Id == productId;
    });
    setProductPricesByQuantities(prPricesByQuantities);
    // console.log(prPricesByQuantities);
  }, [pricesbyquantityData]);

  //-------------Functie de ordonare a unui vector de obiecte dupa cantitate---------------
  //---------------------------------------------------------------------------------------
  function orderByQuantity(vector) {
    const orderedVector = [...vector].sort((a, b) => a.Quantity - b.Quantity);
    return orderedVector;
  }

  //----------Am ordonat obiectele din pricesbyquantity table dupa cantitate-----------------
  //---------------------------------------------------------------------------------------
  useEffect(() => {
    if (productPricesByQuantities) {
      const orderedByQuantity = orderByQuantity(productPricesByQuantities);
      setOrderedPricesByQuantityAfterQuantity(orderedByQuantity);
    }
  }, [productPricesByQuantities]);

  return (
    <>
      {product && (
        <>
          <BackgroundMask />
          <BackgroundImage />
          <WrapContainer>
            <ProductPageHeader>
              <ProductImage>
                <img src={product.Image} style={style.ProductImage} />
              </ProductImage>
              <TitlePriceValabilityDiv>
                <Title>{product.Name}</Title>
                <PriceDiv>
                  {orderedPricesByQuantityAfterQuantity.map((item) => {
                    return (
                      <div key={item.Id} className="d-flex">
                        {item.Price} Ron-
                        <div className="d-flex">
                          {item.Quantity}
                          {currentCategory &&
                            currentCategory.Name == "Săpunuri naturale" && (
                              <p>g</p>
                            )}
                          {currentCategory &&
                            currentCategory.Name != "Săpunuri naturale" && (
                              <p>ml</p>
                            )}
                        </div>
                      </div>
                    );
                  })}
                </PriceDiv>
                <ValabilityDiv>
                  Valabilitate : {product.Valability}
                </ValabilityDiv>
              </TitlePriceValabilityDiv>
            </ProductPageHeader>
            <ProductDescriptionWrap>
              <Descriptions>
                <div className="mt-4">
                  <span
                    style={{
                      color: "rgba(143, 30, 53, 255)",
                      fontSize: "17px",
                      fontWeight: "bold",
                      marginRight: "7px",
                    }}
                  >
                    Ingrediente :
                  </span>
                  <span style={{ fontSize: "16px" }}>{product.Recipe}</span>
                </div>
                <div>
                  <div
                    style={{
                      color: "rgba(143, 30, 53, 255)",
                      fontSize: "17px",
                      fontWeight: "bold",
                      marginBottom: "7px",
                      marginTop: "7px",
                    }}
                  >
                    Despre produs :
                  </div>
                  {productDescriptions.map((item) => {
                    return (
                      <div key={item.Id} className="mb-3">
                        {item.Description}
                      </div>
                    );
                  })}
                </div>
              </Descriptions>
              <Ingredients>
                <div
                  style={{
                    color: "rgba(143, 30, 53, 255)",
                    fontSize: "17px",
                    fontWeight: "bold",
                    marginBottom: "7px",
                    marginTop: "7px",
                  }}
                >
                  Despre ingrediente:
                </div>

                {productIngredients &&
                  productIngredients.map((item) => {
                    return (
                      <div key={item.Id} className="mb-3">
                        <span
                          style={{
                            color: "rgba(143, 30, 53, 255)",
                            fontSize: "17px",
                            marginRight: "5px ",
                            fontWeight: "500",
                          }}
                        >
                          {item.Name}
                        </span>
                        {item.Description}
                      </div>
                    );
                  })}
              </Ingredients>
            </ProductDescriptionWrap>
          </WrapContainer>
        </>
      )}
    </>
  );
};

export default ProductPage;
