import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";

const Wrap = styled(Container)``;

const CategoryList = () => {
  const { categoryData, menuItems, findCurrentMenuItemIds } =
    useOutletContext();
  return (
    <div style={{ marginTop: "180px" }}>
      <Wrap>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Path</th>
              <th scope="col">Menu Item</th>
            </tr>
          </thead>
          <tbody>
            {categoryData.map((item) => {
              const menuItem = menuItems.filter((el) => {
                return el.Id == item.MenuItem;
              });
              return (
                <tr key={item.Id}>
                  <th scope="row">{item.Id}</th>
                  <td>{item.Name}</td>
                  <td>{item.Path}</td>
                  <td>
                    {menuItem.map((el) => {
                      return <div key={el.Name}>{el.Name}</div>;
                    })}
                  </td>

                  <td>
                    <Link
                      to={`/admin/sterge-categorie/${item.Id}`}
                      className="btn btn-danger me-2"
                      style={{ width: "90px", marginBottom: "5px" }}
                    >
                      Șterge
                    </Link>
                    <Link
                      to={`/admin/editeaza-categorie/${item.Id}`}
                      className="btn btn-warning me-2"
                      style={{ width: "90px" }}
                      onClick={() => findCurrentMenuItemIds(item.Name)}
                    >
                      Editează
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Wrap>
    </div>
  );
};

export default CategoryList;
