import React, { useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useOnClickOutside from "../useOnClickOutside";

const DropdownWrap = styled.div`
  // ${({ sideNav }) => !sideNav && "position:absolute;top:65px; z-index:100; "}
  // ${({ sideNav }) => sideNav && ""}
  border-radius: 0 0 20px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  background-color: rgba(243, 218, 214, 1);
  padding: 15px;
  margin-top: 2px;
`;
const DropdownLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  padding: 5px;
  color: black;

  &:hover,
  &:focus {
    color: rgba(135, 190, 211, 255);
  }
  &:active {
    color: palevioletred;
  }
`;

const DropdownMenu = ({
  elemDropdownItems,
  setMenuItemClicked,
  sideNav,
  showSideNav,
  setDropdownActive,
  dropdownActive,
}) => {
  const dropdownRef = useRef();

  useOnClickOutside(dropdownRef, (e) => {
    if (!e.target.id.includes("NavIconCloseId")) {
      console.log("Evrika");
    }
    if (!e.target.className.includes("ignore-outsideClick")) {
      setDropdownActive(!dropdownActive);
    }
  });

  return (
    <>
      {elemDropdownItems && (
        <DropdownWrap
          ref={dropdownRef}
          style={{
            position: !sideNav ? "absolute" : null,
            top: !sideNav ? "65px" : null,
            zIndex: !sideNav ? "100" : null,
          }}
        >
          {elemDropdownItems.map((item) => {
            return (
              <DropdownLink
                className="ignore-outsideClick"
                key={item.Id}
                to={item.Path}
                onClick={() => {
                  setMenuItemClicked(0);
                  if (sideNav) {
                    showSideNav();
                  }
                }}
              >
                {item.Name}
              </DropdownLink>
            );
          })}
        </DropdownWrap>
      )}
    </>
  );
};

export default DropdownMenu;
