import React, { useEffect, useState } from "react";
import AdminMenu from "../AdminMenu";
import Select from "react-select";
import styled from "styled-components";
import { BsPlusSquareFill } from "react-icons/bs";
import { FaMinusSquare } from "react-icons/fa";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const QuantityDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const PricesDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProductDescriptionsDiv = styled.div``;
const ProductSpecificationsDiv = styled.div``;

const EditProduct = () => {
  const {
    categoryData,
    ingredientsData,
    productImagesFolderPath,
    modifiedData,
    setModifiedData,
    currentProduct,
    currentProductCategoryIds,
    currentProductDescriptions,
    currentProductIngredientIds,
    currentPricesByQuantity,
    currentProductSpecifications,
  } = useOutletContext();

  const navigate = useNavigate();
  const params = useParams();
  const productId = parseInt(params.Id);

  const [name, setName] = useState(currentProduct.Name);

  const [productSpecifications, setProductSpecifications] = useState(
    currentProductSpecifications
  );
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(
    currentProductCategoryIds
  );
  const [productImage, setProductImage] = useState(currentProduct.Image);
  const [selectedProductImage, setSelectedProductImage] = useState(null);
  const [recipe, setRecipe] = useState(currentProduct.Recipe);
  const [valability, setValability] = useState(currentProduct.Valability);
  const [quantity, setQuantity] = useState(
    currentPricesByQuantity.map((item) => {
      return item.Quantity;
    })
  );
  const [price, setPrice] = useState(
    currentPricesByQuantity.map((item) => {
      return item.Price;
    })
  );
  const [productDescriptions, setProductDescriptions] = useState(
    currentProductDescriptions
  );
  const [selectedIngredientsIds, setSelectedIngredientsIds] = useState(
    currentProductIngredientIds
  );
  const [formFieldsEmpty, setFormFieldsEmpty] = useState(false);

  //******************************Sectiunea Product Specifications************************************************* */
  //**************************************************************************************************************************************************** */
  const handleProductSpecificationsChange = (e, index) => {
    const newProductSpecifications = [...productSpecifications];
    newProductSpecifications[index] = e.target.value;
    setProductSpecifications(newProductSpecifications);
  };
  const handleProductSpecificationsPlusClick = () => {
    setProductSpecifications([...productSpecifications, ""]);
  };
  const handleProductSpecificationsMinusClick = (index) => {
    const newProductSpecifications = [];
    for (var i = 0; i < productSpecifications.length; i++) {
      if (i != index) {
        newProductSpecifications.push(productSpecifications[i]);
      }
    }
    setProductSpecifications(newProductSpecifications);
  };

  //******************************Am retinut in selectedCategoriesIds id-urile categoriilor selectate************************************************* */
  //**************************************************************************************************************************************************** */

  //Formez un vector de tipul [{value:"",label:""}]
  const options = [];
  categoryData.forEach((item) => {
    const option = {
      value: `${item.Id}`,
      label: `${item.Name}`,
    };
    options.push(option);
  });

  // handle onChange event of the dropdown
  const handleCategoryChange = (e) => {
    setSelectedCategoriesIds(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  //******************************Sectiunea Cantitati si Preturi************************************************* */
  //**************************************************************************************************************************************************** */

  const handleQuantityChange = (e, index) => {
    const newQuantity = [...quantity];
    newQuantity[index] = e.target.value;
    setQuantity(newQuantity);
  };

  const handlePriceChange = (e, index) => {
    const newPrice = [...price];
    newPrice[index] = e.target.value;
    setPrice(newPrice);
  };
  const handlePlusClick = () => {
    setQuantity([...quantity, ""]);
    setPrice([...price, ""]);
  };
  const handleMinusClick = (index) => {
    const newQuantity = [];
    const newPrice = [];
    for (var i = 0; i < quantity.length; i++) {
      if (i != index) {
        newQuantity.push(quantity[i]);
        newPrice.push(price[i]);
      }
    }
    setQuantity(newQuantity);
    setPrice(newPrice);
  };

  //******************************Sectiunea Product Descriptions************************************************* */
  //**************************************************************************************************************************************************** */
  const handleProductDescriptionChange = (e, index) => {
    const newProductDescriptions = [...productDescriptions];
    newProductDescriptions[index] = e.target.value;
    setProductDescriptions(newProductDescriptions);
  };
  const handleProductDescriptionsPlusClick = () => {
    setProductDescriptions([...productDescriptions, ""]);
  };
  const handleProductDescriptionsMinusClick = (index) => {
    const newProductDescriptions = [];
    for (var i = 0; i < productDescriptions.length; i++) {
      if (i != index) {
        newProductDescriptions.push(productDescriptions[i]);
      }
    }
    setProductDescriptions(newProductDescriptions);
  };

  //******************************Am retinut in selectedIngredientsIds id-urile ingredientelor selectate************************************************* */
  //**************************************************************************************************************************************************** */

  //Formez un vector de tipul [{value:"",label:""}]
  const ingredientsOptions = [];
  ingredientsData.forEach((item) => {
    const option = {
      value: `${item.Id}`,
      label: `${item.Name}`,
    };
    ingredientsOptions.push(option);
  });

  // handle onChange event of the dropdown
  const handleIngredientChange = (e) => {
    setSelectedIngredientsIds(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  //****************************************************SUBMIT BUTTON *********************************************************************************************** */
  //****************************************************************************************************************************************************************** */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      selectedCategoriesIds.length == 0 ||
      productImage == null ||
      name == "" ||
      recipe == "" ||
      valability == "" ||
      selectedIngredientsIds.length == 0 ||
      productDescriptions.length == 0
    ) {
      setFormFieldsEmpty(true);
      alert("Nu lasati campuri goale");
    }

    for (var i = 0; i < quantity.length; i++) {
      if (quantity[i] == "" || price[i] == "") {
        setFormFieldsEmpty(true);
        alert("Nu lasati campuri goale");
      }
    }

    if (formFieldsEmpty == false) {
      //-------------------SETEZ IMAGINEA PT NOUL PRODUS-----------------------
      if (selectedProductImage != null) {
        //--------FAC UPLOAD LA NOUA IMAGINE IN FOLDER SI EDITEZ PATH-UL IN PRODUCTS TABLE-----------
        const formDataImage = new FormData();
        formDataImage.append("product_image", selectedProductImage);
        formDataImage.append("id", productId);
        formDataImage.append(
          "uploadedImagePath",
          productImagesFolderPath[0].Path
        );

        const configImage = {
          method: "POST",
          //cu acest header prezent nu merge postarea cu form data
          // headers: { "Content-Type": "application/json" },
          body: formDataImage,
        };
        fetch("uploadImage.php", configImage)
          .then((response) => response.text())
          .then((response) => {
            console.log(response);
          })

          .then(() => {
            setModifiedData(!modifiedData);
          })
          .then(() => navigate("/admin/lista-produse"));
      }

      const newProduct = {
        Id: productId,
        Name: name,
        ProductSpecifications: productSpecifications,
        Recipe: recipe,
        Valability: valability,
        SelectedCategoriesIds: selectedCategoriesIds,
        ProductDescriptions: productDescriptions,
        Quantity: quantity,
        Price: price,
        SelectedIngredientsIds: selectedIngredientsIds,
      };

      const config = {
        method: "PATCH",
        body: JSON.stringify(newProduct),
      };
      fetch("productsCRUD.php", config)
        // .then((response) => response.text())
        // .then((response) => {
        //   console.log(response);
        // })
        .then(() => setModifiedData(!modifiedData))
        .then(() => navigate("/admin/lista-produse"));
    }
  };

  return (
    <div style={{ marginTop: "180px" }}>
      <form
        style={{ width: "70%", margin: "auto", marginTop: "70px" }}
        onSubmit={handleSubmit}
      >
        <h3>Editează produs</h3>
        <div className="mb-3 mt-3">
          <label> Nume produs</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        {/* ************************************Specificatiile produsului********************************* */}
        <div className="mb-3 ">
          <label className="form-label text-danger">
            Specificațiile produsului
          </label>
          <ProductSpecificationsDiv>
            {productSpecifications.map((item, index) => {
              return (
                <div key={index} className="d-flex">
                  <textarea
                    rows="4"
                    className="form-control mb-2"
                    placeholder="Specificația produsului"
                    value={item}
                    onChange={(e) =>
                      handleProductSpecificationsChange(e, index)
                    }
                  />
                  {index == 0 && (
                    <BsPlusSquareFill
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={handleProductSpecificationsPlusClick}
                    />
                  )}
                  {index > 0 && (
                    <FaMinusSquare
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={() =>
                        handleProductSpecificationsMinusClick(index)
                      }
                    />
                  )}
                </div>
              );
            })}
          </ProductSpecificationsDiv>
        </div>
        {/* ************************************Select List pt categorie********************************* */}
        <div className="mb-3">
          <label className="form-label text-danger">Alegeti categoriile:</label>
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={options.filter((obj) =>
              selectedCategoriesIds.includes(obj.value)
            )} // set selected values
            options={options} // set list of the data
            onChange={handleCategoryChange} // assign onChange function
            isMulti
            isClearable
          />
        </div>
        {/* ***************************************Imagine********************************* */}
        <div className="mb-3">
          <label className="form-label me-3 text-danger">
            Imaginea produsului:
          </label>

          <img src={currentProduct.Image} style={{ width: "80px" }} />
        </div>
        <div className="mb-3">
          <label className="form-label me-3 text-danger">
            Alegeti o noua imagine:
          </label>
          <input
            type="file"
            onChange={(e) => {
              setSelectedProductImage(e.target.files[0]);
            }}
          />
        </div>
        <div className="mb-3">
          <label>Rețetă</label>
          <textarea
            rows="4"
            type="text"
            className="form-control"
            value={recipe}
            onChange={(event) => {
              setRecipe(event.target.value);
            }}
          />
        </div>

        <div className="mb-3">
          <label>Valabilitate</label>
          <input
            type="text"
            className="form-control"
            value={valability}
            onChange={(event) => {
              setValability(event.target.value);
            }}
          />
        </div>
        {/* ************************************Descrierea produsului********************************* */}
        <div className="mb-3 ">
          <label className="form-label text-danger">
            Descrierile produsului
          </label>
          <ProductDescriptionsDiv>
            {productDescriptions.map((item, index) => {
              return (
                <div key={index} className="d-flex">
                  <textarea
                    rows="4"
                    className="form-control mb-2"
                    placeholder="Descrierea produsului"
                    value={item}
                    onChange={(e) => handleProductDescriptionChange(e, index)}
                  />
                  {index == 0 && (
                    <BsPlusSquareFill
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={handleProductDescriptionsPlusClick}
                    />
                  )}
                  {index > 0 && (
                    <FaMinusSquare
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={() => handleProductDescriptionsMinusClick(index)}
                    />
                  )}
                </div>
              );
            })}
          </ProductDescriptionsDiv>
        </div>

        {/* ************************************Preturi si Cantitati********************************* */}
        <div className=" mb-3">
          <label className="form-label text-danger">
            Stabiliti gramajul si pretul aferent:
          </label>
          <div className="d-flex">
            <QuantityDiv>
              {quantity.map((item, index) => {
                return (
                  <div key={index} className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Cantitatea"
                      style={{ width: "200px" }}
                      value={item}
                      onChange={(e) => handleQuantityChange(e, index)}
                    />
                    <div style={{ marginRight: "10px" }}> ml</div>
                  </div>
                );
              })}
            </QuantityDiv>
            <PricesDiv>
              {price.map((item, index) => {
                return (
                  <div key={index} className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Pretul"
                      style={{ width: "200px" }}
                      value={item}
                      onChange={(e) => handlePriceChange(e, index)}
                    />
                    <div style={{ marginRight: "10px" }}> ron</div>
                    {index == 0 && (
                      <BsPlusSquareFill
                        style={{
                          fontSize: "30px",
                          color: "rgba(228, 220, 233, 1)",
                          marginRight: "10px",
                        }}
                        onClick={handlePlusClick}
                      />
                    )}
                    {index > 0 && (
                      <FaMinusSquare
                        style={{
                          fontSize: "30px",
                          color: "rgba(228, 220, 233, 1)",
                          marginRight: "10px",
                        }}
                        onClick={() => handleMinusClick(index)}
                      />
                    )}
                  </div>
                );
              })}
            </PricesDiv>
          </div>
        </div>
        {/* ************************************Ingredientele descrise********************************* */}
        <div className="mb-3 ">
          <label className="form-label text-danger">
            Alegeti ingredientele descrise:
          </label>
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={ingredientsOptions.filter((obj) =>
              selectedIngredientsIds.includes(obj.value)
            )} // set selected values
            options={ingredientsOptions} // set list of the data
            onChange={handleIngredientChange} // assign onChange function
            isMulti
            isClearable
          />
        </div>

        <button type="submit" className="btn btn-info w-25">
          <p>Editează</p>
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
