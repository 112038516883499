import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminMenu from "../AdminMenu";

const AddIngredient = () => {
  const { ingredientsData, modifiedData, setModifiedData } = useOutletContext();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [exists, setExists] = useState(false);

  useEffect(() => {
    setExists(false);
    ingredientsData.forEach((item) => {
      if (item.Name.toLowerCase() == name.toLowerCase()) {
        setExists(true);
      }
    });
  }, [name]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const ingredient = {
      Name: name,
      Description: description,
    };

    const config = {
      method: "POST",
      body: JSON.stringify(ingredient),
    };

    //  Corectez in baza de date
    fetch("ingredientsCRUD.php", config)
      .then((rezultat) => rezultat.text())
      .then((rez) => console.log(rez))
      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-ingrediente"));
  };
  return (
    <div style={{ marginTop: "180px" }}>
      <form
        style={{ width: "70%", margin: "auto", marginTop: "70px" }}
        onSubmit={handleSubmit}
      >
        <h3>Adaugă ingredient</h3>
        <div className="mb-3 mt-3">
          <label> Nume</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          {exists && (
            <div style={{ color: "red", fontWeight: "700" }}> EXISTĂ !!! </div>
          )}
        </div>
        <div className="mb-3">
          <label> Descriere</label>
          <textarea
            rows={6}
            type="text"
            className="form-control"
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </div>

        <button type="submit" className="btn btn-info w-25">
          <p>Adaugă</p>
        </button>
      </form>
    </div>
  );
};

export default AddIngredient;
