import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import SideNav from "./SideNav";
import DropdownMenu from "./DropdownMenu";
import * as AiIcons from "react-icons/ai";
import Svg from "../Svg/logo-elephant.svg";

const BackgroundMask = styled.div`
  width: 100%;
  height: 80px;
  top: 0;
  position: fixed;
  z-index: -99;
`;

const Navbar = styled.div`
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(243, 218, 214, 1);
  position: fixed;
  top: 0px;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 750px) {
    justify-content: center;
  }

  @media screen and (max-width: 750px) {
    padding: 0px 20px;
  }
`;

const LogoImageMobile = styled.div`
  background-image: url(${Svg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;

  @media screen and (min-width: 650px) {
    display: none;
  }
`;
const LucilleDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  font-family: "Lavishly Yours", cursive;
  font-weight: 700;
  background: -webkit-linear-gradient(
    rgba(35, 61, 92, 255),
    rgba(135, 190, 211, 255)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (min-width: 650px) {
    display: none;
  }
`;
const LogoImageDesktop = styled.div`
  background-image: url(${Svg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
`;
const Menu = styled.div`
  with: 90%;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 650px) {
    display: none;
  }
`;
const HambourgerIcon = styled.div`
  color: #8f1e35;
  border-radius: 7px;
  position: absolute;
  right: 30px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 103;
  padding: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  @media screen and (min-width: 650px) {
    display: none;
  }
`;
const NavLink = styled(Link)`
  //border: 2px solid red;
  font-weight: 500;
  cursor: pointer;
  color: black;
  text-decoration: none;
  &:hover,
  &:focus {
    color: rgba(135, 190, 211, 255);
  }
  &:active {
    color: palevioletred;
  }
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;
const NavLinkItem = styled.div`
  //border: 2px solid yellow;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    color: rgba(135, 190, 211, 255);
  }
  &:active {
    color: palevioletred;
  }
`;
const BlackLine = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: fixed;
  top: 0;
  z-index: 101;
  height: 30px;
  font-weight: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Baloo Paaji 2", cursive;
`;
const Header = ({ sideNav, showSideNav, menuItems, categoryData }) => {
  const [menuItemClicked, setMenuItemClicked] = useState(0);
  const [dropdownActive, setDropdownActive] = useState(false);
  return (
    <>
      <BackgroundMask />
      {/* <BlackLine>
        Transport prin curier : 16ron cu plata în cont, 20ron cu ramburs
      </BlackLine> */}
      {/* ---------------------------------Meniul lateral pe telefon--------------------------------- */}
      {sideNav && (
        <SideNav
          showSideNav={showSideNav}
          sideNav={sideNav}
          menuItems={menuItems}
          menuItemClicked={menuItemClicked}
          setMenuItemClicked={setMenuItemClicked}
          categoryData={categoryData}
          setDropdownActive={setDropdownActive}
          dropdownActive={dropdownActive}
        />
      )}

      <Navbar>
        <Link to="/">
          <LogoImageMobile />
        </Link>
        <Link to="/">
          <LucilleDiv>Lucille's</LucilleDiv>
        </Link>

        {/* ---------------------------------Meniul pe telefon ca Hambourger Icon--------------------------------- */}
        <HambourgerIcon onClick={showSideNav}>
          <FaBars />
        </HambourgerIcon>

        {/* ---------------------------------Meniul pe larg--------------------------------- */}
        <Menu>
          {/* ------Afisez prima jumatate a itemurilor din meniu------ */}

          {categoryData.length != 0 &&
            menuItems
              .filter((menuItem) => {
                return (
                  menuItem.Name == "Home" ||
                  menuItem.Name == "Îngrijirea feței" ||
                  menuItem.Name == "Îngrijirea corpului"
                );
              })

              .map((elem) => {
                if (elem.hasdropdown == "yes") {
                  const elemDropdownItems = categoryData.filter(
                    (dropdownItem) => {
                      return dropdownItem.MenuItem == elem.Name;
                    }
                  );

                  return (
                    <div key={elem.Id} style={{ padding: "20px" }}>
                      <NavLinkItem
                        onClick={() => {
                          setDropdownActive(!dropdownActive);
                          setMenuItemClicked(elem.Id);
                        }}
                      >
                        <NavLink to={elem.Path}>{elem.Name}</NavLink>
                        {elem.hasdropdown == "yes" && (
                          <AiIcons.AiFillCaretDown
                            style={{ marginLeft: "4px" }}
                          />
                        )}
                        {elem.hasdropdown == "yes" &&
                          menuItemClicked == elem.Id &&
                          dropdownActive == true && (
                            <DropdownMenu
                              sideNav={sideNav}
                              elemDropdownItems={elemDropdownItems}
                              menuItemClicked={menuItemClicked}
                              setMenuItemClicked={setMenuItemClicked}
                              showSideNav={showSideNav}
                              setDropdownActive={setDropdownActive}
                              dropdownActive={dropdownActive}
                            />
                          )}
                      </NavLinkItem>
                    </div>
                  );
                } else {
                  return (
                    <NavLinkItem key={elem.Id}>
                      <NavLink to={elem.Path}>{elem.Name}</NavLink>
                    </NavLinkItem>
                  );
                }
              })}

          {/* ---------------------------------LOGO--------------------------------- */}

          <Link to="/">
            <LogoImageDesktop />
          </Link>

          {/* ------Afisez a doua jumatate a itemurilor din meniu------ */}
          {categoryData.length != 0 &&
            menuItems
              .filter((menuItem) => {
                return (
                  menuItem.Name == "Îngrijirea părului" ||
                  menuItem.Name == "Probleme dermatologice" ||
                  menuItem.Name == "Sarcină/Alăptare"
                );
              })

              .map((elem) => {
                if (elem.hasdropdown == "yes") {
                  const elemDropdownItems = categoryData.filter(
                    (dropdownItem) => {
                      return dropdownItem.MenuItem == elem.Name;
                    }
                  );

                  return (
                    <div key={elem.Id} style={{ padding: "20px" }}>
                      <NavLinkItem
                        onClick={() => {
                          setDropdownActive(!dropdownActive);
                          setMenuItemClicked(elem.Id);
                        }}
                      >
                        <NavLink to={elem.Path}>{elem.Name}</NavLink>
                        {elem.hasdropdown == "yes" && (
                          <AiIcons.AiFillCaretDown
                            style={{ marginLeft: "4px" }}
                          />
                        )}
                        {elem.hasdropdown == "yes" &&
                          menuItemClicked == elem.Id &&
                          dropdownActive == true && (
                            <DropdownMenu
                              sideNav={sideNav}
                              elemDropdownItems={elemDropdownItems}
                              menuItemClicked={menuItemClicked}
                              setMenuItemClicked={setMenuItemClicked}
                              showSideNav={showSideNav}
                              setDropdownActive={setDropdownActive}
                              dropdownActive={dropdownActive}
                            />
                          )}
                      </NavLinkItem>
                    </div>
                  );
                } else {
                  return (
                    <NavLinkItem
                      key={elem.Id}
                      onClick={() => setMenuItemClicked(0)}
                    >
                      <NavLink to={elem.Path}>{elem.Name}</NavLink>
                    </NavLinkItem>
                  );
                }
              })}
        </Menu>
      </Navbar>
    </>
  );
};

export default Header;
