import React from "react";
import { FcSearch } from "react-icons/fc";
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
`;
const SearchIcon = styled.div`
  font-size: 30px;
  margin-left: 5px;
`;
const SearchBar = ({
  filterText,
  setFilterText,
  onSearchBarTextChange,
  list,
}) => {
  return (
    <Wrap>
      <input
        type="text"
        style={{ border: "2px solid rgba(143, 30, 53, 255)" }}
        value={filterText}
        placeholder="Search"
        onChange={(event) => {
          setFilterText(event.target.value);
          onSearchBarTextChange(event.target.value, list);
        }}
      />
      <SearchIcon>
        <FcSearch />
      </SearchIcon>
    </Wrap>
  );
};

export default SearchBar;
