import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import { useParams, useNavigate, useOutletContext } from "react-router-dom";

const Wrap = styled(Container)``;

const EditIngredient = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const ingredientId = parseInt(params.Id);

  const { ingredientsData, modifiedData, setModifiedData } = useOutletContext();

  useEffect(() => {
    if (ingredientsData.length != 0) {
      const currentIngredient = ingredientsData.find((item) => {
        return parseInt(item.Id) == parseInt(ingredientId);
      });

      setName(currentIngredient.Name);
      setDescription(currentIngredient.Description);
    }
  }, [ingredientsData]);

  //   ------------------------------HANDLE SUBMIT----------------------------
  const handleSubmit = (e) => {
    e.preventDefault();

    const ingredient = {
      Id: parseInt(ingredientId),
      Name: name,
      Description: description,
    };

    const config = {
      method: "PATCH",
      body: JSON.stringify(ingredient),
    };

    //  Corectez in baza de date
    fetch("ingredientsCRUD.php", config)
      // .then((rezultat) => rezultat.text())
      // .then((rez) => console.log(rez))
      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-ingrediente"));
  };
  return (
    <div style={{ marginTop: "180px" }}>
      <Wrap>
        <form
          style={{ width: "70%", margin: "auto", marginTop: "70px" }}
          onSubmit={handleSubmit}
        >
          <h3>Editează ingredient</h3>
          <div className="mb-3 mt-3">
            <label> Nume</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label> Path</label>
            <input
              type="text"
              className="form-control"
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </div>

          <button type="submit" className="btn btn-info w-25">
            <p>Editează</p>
          </button>
        </form>
      </Wrap>
    </div>
  );
};

export default EditIngredient;
