import React, { useState } from "react";
import AdminMenu from "../AdminMenu";
import Select from "react-select";
import styled from "styled-components";
import { BsPlusSquareFill } from "react-icons/bs";
import { FaMinusSquare } from "react-icons/fa";
import { useNavigate, useOutlet, useOutletContext } from "react-router-dom";

const QuantityDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const PricesDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProductDescriptionsDiv = styled.div``;
const ProductSpecificationsDiv = styled.div``;
const Label = styled.label`
  color: rgba(143, 30, 53, 255);
`;

const AddProduct = ({}) => {
  const {
    categoryData,
    ingredientsData,
    productImagesFolderPath,
    modifiedData,
    setModifiedData,
  } = useOutletContext();

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [productSpecifications, setProductSpecifications] = useState([""]);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);
  const [selectedProductImage, setSelectedProductImage] = useState(null);
  const [recipe, setRecipe] = useState("");
  const [valability, setValability] = useState("");
  const [quantity, setQuantity] = useState([""]);
  const [price, setPrice] = useState([""]);
  const [productDescriptions, setProductDescriptions] = useState([""]);
  const [selectedIngredientsIds, setSelectedIngredientsIds] = useState([]);
  const [formFieldsEmpty, setFormFieldsEmpty] = useState(false);
  const [invisibilityCheckBox, setInvisibilityCheckBox] = useState(false);

  //******************************Sectiunea Product Specifications************************************************* */
  //**************************************************************************************************************************************************** */
  const handleProductSpecificationsChange = (e, index) => {
    const newProductSpecifications = [...productSpecifications];
    newProductSpecifications[index] = e.target.value;
    setProductSpecifications(newProductSpecifications);
  };
  const handleProductSpecificationsPlusClick = () => {
    setProductSpecifications([...productSpecifications, ""]);
  };
  const handleProductSpecificationsMinusClick = (index) => {
    const newProductSpecifications = [];
    for (var i = 0; i < productSpecifications.length; i++) {
      if (i != index) {
        newProductSpecifications.push(productSpecifications[i]);
      }
    }
    setProductSpecifications(newProductSpecifications);
  };

  //******************************Am retinut in selectedCategoriesIds id-urile categoriilor selectate************************************************* */
  //**************************************************************************************************************************************************** */

  //Formez un vector de tipul [{value:"",label:""}]
  const options = [];
  categoryData.forEach((item) => {
    const option = {
      value: `${item.Id}`,
      label: `${item.Name}`,
    };
    options.push(option);
  });
  // handle onChange event of the dropdown
  const handleCategoryChange = (e) => {
    setSelectedCategoriesIds(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  //******************************Sectiunea Cantitati si Preturi************************************************* */
  //**************************************************************************************************************************************************** */

  const handleQuantityChange = (e, index) => {
    const newQuantity = [...quantity];
    newQuantity[index] = e.target.value;
    setQuantity(newQuantity);
  };

  const handlePriceChange = (e, index) => {
    const newPrice = [...price];
    newPrice[index] = e.target.value;
    setPrice(newPrice);
  };
  const handlePlusClick = () => {
    setQuantity([...quantity, ""]);
    setPrice([...price, ""]);
  };
  const handleMinusClick = (index) => {
    const newQuantity = [];
    const newPrice = [];
    for (var i = 0; i < quantity.length; i++) {
      if (i != index) {
        newQuantity.push(quantity[i]);
        newPrice.push(price[i]);
      }
    }
    setQuantity(newQuantity);
    setPrice(newPrice);
  };

  //******************************Sectiunea Product Descriptions************************************************* */
  //**************************************************************************************************************************************************** */
  const handleProductDescriptionChange = (e, index) => {
    const newProductDescriptions = [...productDescriptions];
    newProductDescriptions[index] = e.target.value;
    setProductDescriptions(newProductDescriptions);
  };
  const handleProductDescriptionsPlusClick = () => {
    setProductDescriptions([...productDescriptions, ""]);
  };
  const handleProductDescriptionsMinusClick = (index) => {
    const newProductDescriptions = [];
    for (var i = 0; i < productDescriptions.length; i++) {
      if (i != index) {
        newProductDescriptions.push(productDescriptions[i]);
      }
    }
    setProductDescriptions(newProductDescriptions);
  };

  //******************************Am retinut in selectedIngredientsIds id-urile ingredientelor selectate************************************************* */
  //**************************************************************************************************************************************************** */

  //Formez un vector de tipul [{value:"",label:""}]
  const ingredientsOptions = [];
  ingredientsData.forEach((item) => {
    const option = {
      value: `${item.Id}`,
      label: `${item.Name}`,
    };
    ingredientsOptions.push(option);
  });

  // handle onChange event of the dropdown
  const handleIngredientChange = (e) => {
    setSelectedIngredientsIds(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  //****************************************************INVISIBILITY CHECKBOX *********************************************************************************************** */
  //****************************************************************************************************************************************************************** */
  const handleCheckBoxChange = (event) => {
    setInvisibilityCheckBox(event.target.checked);
  };
  //****************************************************SUBMIT BUTTON *********************************************************************************************** */
  //****************************************************************************************************************************************************************** */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      selectedCategoriesIds.length == 0 ||
      selectedProductImage == null ||
      name == "" ||
      recipe == "" ||
      valability == "" ||
      selectedIngredientsIds.length == 0 ||
      productDescriptions.length == 0
    ) {
      setFormFieldsEmpty(true);
      alert("Nu lasati campuri goale");
    }

    for (var i = 0; i < quantity.length; i++) {
      if (quantity[i] == "" || price[i] == "") {
        setFormFieldsEmpty(true);
        alert("Nu lasati campuri goale");
      }
    }

    if (formFieldsEmpty == false) {
      //-------------------SETEZ  NOUL PRODUS-----------------------
      const formData = new FormData(); //retine cheie-valoare

      //Pt un vector este necesar simbolul [] la cheie, dar in back-end
      //nu va fi necesar
      selectedCategoriesIds.forEach((id) => {
        formData.append("selectedCategoriesIds[]", id);
      });
      formData.append("product_image", selectedProductImage);

      formData.append(
        "productImagesFolderPath",
        productImagesFolderPath[0].Path
      );
      formData.append("name", name);
      productSpecifications.forEach((item) => {
        formData.append("productSpecifications[]", item);
      });
      formData.append("recipe", recipe);
      formData.append("valability", valability);
      quantity.forEach((item) => {
        formData.append("quantity[]", item);
      });
      price.forEach((item) => {
        formData.append("price[]", item);
      });
      productDescriptions.forEach((item) => {
        formData.append("productDescriptions[]", item);
      });
      selectedIngredientsIds.forEach((id) => {
        formData.append("selectedIngredientsIds[]", id);
      });
      if (invisibilityCheckBox == true) {
        formData.append("invisibilityCheckBox", "Invizibil");
      } else {
        formData.append("invisibilityCheckBox", "Vizibil");
      }

      const config = {
        method: "POST",
        body: formData,
      };
      fetch("productsCRUD.php", config)
        // .then((response) => response.text())
        // .then((response) => {
        //   console.log(response);
        // })
        .then(() => {
          setModifiedData(!modifiedData);
          // navigate("/admin/lista-produse");
        })
        .then(() => navigate("/admin/lista-produse"));
    }
  };

  return (
    <div style={{ marginTop: "50px", textAlign: "center", width: "100%" }}>
      <h3 style={{ padding: "20px" }}>Adaugă produs</h3>
      <form onSubmit={handleSubmit}>
        {/* ************************************Numele produsului********************************* */}
        <div className="mb-3 mt-3">
          <Label> Nume produs</Label>
          <input
            placeholder="Nume produs"
            type="text"
            className="form-control"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>

        {/* ************************************Specificatiile produsului********************************* */}
        <div className="mb-3 ">
          <Label className="form-label ">Specificațiile produsului</Label>
          <ProductSpecificationsDiv>
            {productSpecifications.map((item, index) => {
              return (
                <div key={index} className="d-flex">
                  <input
                    rows="4"
                    className="form-control mb-2"
                    placeholder="Specificația produsului"
                    value={item}
                    onChange={(e) =>
                      handleProductSpecificationsChange(e, index)
                    }
                  />
                  {index == 0 && (
                    <BsPlusSquareFill
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={handleProductSpecificationsPlusClick}
                    />
                  )}
                  {index > 0 && (
                    <FaMinusSquare
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={() =>
                        handleProductSpecificationsMinusClick(index)
                      }
                    />
                  )}
                </div>
              );
            })}
          </ProductSpecificationsDiv>
        </div>
        {/* ************************************Select List pt categorie********************************* */}
        <div className="mb-3">
          <Label className="form-label ">Alegeti categoriile:</Label>
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={options.filter((obj) =>
              selectedCategoriesIds.includes(obj.value)
            )} // set selected values
            options={options} // set list of the data
            onChange={handleCategoryChange} // assign onChange function
            isMulti
            isClearable
          />
        </div>

        <div className="mb-3">
          <Label>Rețetă</Label>

          <textarea
            rows="4"
            type="text"
            className="form-control"
            value={recipe}
            onChange={(event) => {
              setRecipe(event.target.value);
            }}
          />
        </div>

        <div className="mb-3">
          <Label>Valabilitate</Label>
          <input
            type="text"
            className="form-control"
            value={valability}
            onChange={(event) => {
              setValability(event.target.value);
            }}
          />
        </div>
        {/* ************************************Descrierea produsului********************************* */}
        <div className="mb-3 ">
          <Label className="form-label ">Descrierile produsului</Label>
          <ProductDescriptionsDiv>
            {productDescriptions.map((item, index) => {
              return (
                <div key={index} className="d-flex">
                  <textarea
                    rows="4"
                    className="form-control mb-2"
                    placeholder="Descrierea produsului"
                    value={item}
                    onChange={(e) => handleProductDescriptionChange(e, index)}
                  />
                  {index == 0 && (
                    <BsPlusSquareFill
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={handleProductDescriptionsPlusClick}
                    />
                  )}
                  {index > 0 && (
                    <FaMinusSquare
                      style={{
                        fontSize: "30px",
                        color: "rgba(228, 220, 233, 1)",
                        marginRight: "10px",
                      }}
                      onClick={() => handleProductDescriptionsMinusClick(index)}
                    />
                  )}
                </div>
              );
            })}
          </ProductDescriptionsDiv>
        </div>

        {/* ************************************Preturi si Cantitati********************************* */}
        <div className=" mb-3">
          <Label className="form-label ">
            Stabiliti gramajul si pretul aferent:
          </Label>
          <div className="d-flex">
            <QuantityDiv>
              {quantity.map((item, index) => {
                return (
                  <div key={index} className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Cantitatea"
                      style={{ width: "200px" }}
                      value={item}
                      onChange={(e) => handleQuantityChange(e, index)}
                    />
                    <div style={{ marginRight: "10px" }}> ml</div>
                  </div>
                );
              })}
            </QuantityDiv>
            <PricesDiv>
              {price.map((item, index) => {
                return (
                  <div key={index} className="d-flex align-items-center">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Pretul"
                      style={{ width: "200px" }}
                      value={item}
                      onChange={(e) => handlePriceChange(e, index)}
                    />
                    <div style={{ marginRight: "10px" }}> ron</div>
                    {index == 0 && (
                      <BsPlusSquareFill
                        style={{
                          fontSize: "30px",
                          color: "rgba(228, 220, 233, 1)",
                          marginRight: "10px",
                        }}
                        onClick={handlePlusClick}
                      />
                    )}
                    {index > 0 && (
                      <FaMinusSquare
                        style={{
                          fontSize: "30px",
                          color: "rgba(228, 220, 233, 1)",
                          marginRight: "10px",
                        }}
                        onClick={() => handleMinusClick(index)}
                      />
                    )}
                  </div>
                );
              })}
            </PricesDiv>
          </div>
        </div>
        {/* ************************************Ingredientele descrise********************************* */}
        <div className="mb-3 ">
          <Label className="form-label ">Alegeti ingredientele descrise:</Label>
          <Select
            className="dropdown"
            placeholder="Select Option"
            value={ingredientsOptions.filter((obj) =>
              selectedIngredientsIds.includes(obj.value)
            )} // set selected values
            options={ingredientsOptions} // set list of the data
            onChange={handleIngredientChange} // assign onChange function
            isMulti
            isClearable
          />
        </div>
        {/* ***************************************Imagine********************************* */}
        <div className="mb-3">
          <Label className="form-label me-3 ">
            Alegeti imaginea produsului
          </Label>
          <input
            type="file"
            onChange={(e) => {
              setSelectedProductImage(e.target.files[0]);
            }}
          />
        </div>
        {/* ************************************Vizibilitatea produsului********************************* */}
        <div className="mb-3">
          <Label className="form-label me-3 ">
            Bifați dacă doriți ca produsul să fie invizibil utilizatorilor:
          </Label>
          <input
            style={{ width: "20px", height: "20px" }}
            type="checkbox"
            checked={invisibilityCheckBox}
            onChange={handleCheckBoxChange}
          />
        </div>

        <button type="submit" className="btn btn-info w-25 mt-3 mb-5">
          <p>Adaugă</p>
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
