import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Wrap = styled(Container)`
  margin-top: 180px;
`;

const DeleteIngredient = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const ingredientId = parseInt(params.Id);

  const { ingredientsData, modifiedData, setModifiedData, setLogged } =
    useOutletContext();

  useEffect(() => {
    if (ingredientsData.length != 0) {
      const currentIngredient = ingredientsData.find((item) => {
        return parseInt(item.Id) == parseInt(ingredientId);
      });

      setName(currentIngredient.Name);
      setDescription(currentIngredient.Description);
    }
  }, [ingredientsData]);

  //   ------------------------------HANDLE SUBMIT----------------------------
  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      method: "DELETE",
      body: JSON.stringify({ id: parseInt(ingredientId, 10) }),
    };

    //  Corectez in baza de date
    fetch("ingredientsCRUD.php", config)
      // .then((rezultat) => rezultat.text())
      // .then((rez) => console.log(rez))

      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-ingrediente"));
  };
  return (
    <>
      {ingredientsData && (
        <Wrap>
          <form
            style={{ width: "70%", margin: "auto", marginTop: "70px" }}
            onSubmit={handleSubmit}
          >
            <h3>Șterge ingredient. Sunteți sigur? </h3>
            <div className="mb-3 mt-3">
              <label> Nume</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </div>
            <div className="mb-3">
              <label> Path</label>
              <input
                type="text"
                className="form-control"
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />
            </div>

            <button type="submit" className="btn btn-danger w-25">
              <p>Șterge</p>
            </button>
          </form>
        </Wrap>
      )}
    </>
  );
};

export default DeleteIngredient;
