import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import ProductListPage from "./ProductListPage";
import PageNotFound from "./PageNotFound";
import LoginPage from "./Admin/LoginPage";
import ProductList from "./Admin/Product/ProductList";
import AddProduct from "./Admin/Product/AddProduct";
import CategoryList from "./Admin/Category/CategoryList";
import AddCategory from "./Admin/Category/AddCategory";
import EditCategory from "./Admin/Category/EditCategory";
import DeleteCategory from "./Admin/Category/DeleteCategory";
import IngredientList from "./Admin/Ingredient/IngredientList";
import AddIngredient from "./Admin/Ingredient/AddIngredient";
import EditIngredient from "./Admin/Ingredient/EditIngredient";
import DeleteIngredient from "./Admin/Ingredient/DeleteIngredient";
import DeleteProduct from "./Admin/Product/DeleteProduct";
import EditProduct from "./Admin/Product/EditProduct";
import ProductPage from "./ProductPage";
import WelcomeAdmin from "./Admin/WelcomeAdmin";

const Views = ({
  productImagesFolderPath,
  modifiedData,
  setModifiedData,
  productsData,
  categoryData,
  product_categoryData,
  productDescriptionsData,
  categoryDescriptionsData,
  pricesbyquantityData,
  ingredientsData,
  product_ingredientData,
  logged,
  setLogged,
  menuItems,
  findCurrentProduct,
  currentProduct,
  currentProductCategoryIds,
  currentProductDescriptions,
  currentProductIngredientIds,
  currentPricesByQuantity,
  currentProductSpecifications,
  productSpecificationsData,
  onSearchBarTextChange,
  productSearchableList,
  setProductSearchableList,
  ingredientSearchableList,
  setIngredientSearchableList,
  findCurrentMenuItemIds,
  currentMenuItemIds,
  filterTextProductList,
  setFilterTextProductList,
  filterTextIngredientList,
  setFilterTextIngredientList,
}) => {
  return (
    <Routes>
      <Route path="/" element={<HomePage productsData={productsData} />} />
      <Route
        path="/produse/:Id"
        element={
          <ProductPage
            productsData={productsData}
            pricesbyquantityData={pricesbyquantityData}
            productDescriptionsData={productDescriptionsData}
            product_ingredientData={product_ingredientData}
            ingredientsData={ingredientsData}
            product_categoryData={product_categoryData}
            categoryData={categoryData}
          />
        }
      />

      {/* ---------------------------------------------------Rutele pt fiecare pagina din meniu ------------------------------------------------------------------ */}
      {/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      {categoryData.map((category) => {
        return (
          <Route
            key={category.Name}
            path={category.Path}
            element={
              <ProductListPage
                category={category}
                product_categoryData={product_categoryData}
                productsData={productsData}
                modifiedData={modifiedData}
                categoryDescriptionsData={categoryDescriptionsData}
                pricesbyquantityData={pricesbyquantityData}
                productSpecificationsData={productSpecificationsData}
                categoryData={categoryData}
              />
            }
          />
        );
      })}

      <Route to="*" element={<PageNotFound />} />

      {/* ----------------------------------------------------------------------ADMIN ------------------------------------------------------------------ */}
      {/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */}

      <Route
        path="/admin"
        element={
          <LoginPage
            menuItems={menuItems}
            logged={logged}
            setLogged={setLogged}
            productSearchableList={productSearchableList}
            setProductSearchableList={setProductSearchableList}
            ingredientSearchableList={ingredientSearchableList}
            setIngredientSearchableList={setIngredientSearchableList}
            ingredientsData={ingredientsData}
            onSearchBarTextChange={onSearchBarTextChange}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            productsData={productsData}
            product_categoryData={product_categoryData}
            categoryData={categoryData}
            productDescriptionsData={productDescriptionsData}
            product_ingredientData={product_ingredientData}
            pricesbyquantityData={pricesbyquantityData}
            productSpecificationsData={productSpecificationsData}
            productImagesFolderPath={productImagesFolderPath}
            findCurrentProduct={findCurrentProduct}
            currentProduct={currentProduct}
            currentProductCategoryIds={currentProductCategoryIds}
            currentProductDescriptions={currentProductDescriptions}
            currentProductIngredientIds={currentProductIngredientIds}
            currentPricesByQuantity={currentPricesByQuantity}
            currentProductSpecifications={currentProductSpecifications}
            findCurrentMenuItemIds={findCurrentMenuItemIds}
            currentMenuItemIds={currentMenuItemIds}
            filterTextProductList={filterTextProductList}
            setFilterTextProductList={setFilterTextProductList}
            filterTextIngredientList={filterTextIngredientList}
            setFilterTextIngredientList={setFilterTextIngredientList}
          />
        }
      >
        <Route index element={<WelcomeAdmin />} />

        {/* --------------------- INGREDIENTE -------------------------- */}
        {/* --------------------------------------------------------------- */}

        <Route path="/admin/lista-ingrediente" element={<IngredientList />} />

        <Route path="/admin/adauga-ingredient" element={<AddIngredient />} />

        <Route path="/admin/editeaza-ingredient">
          <Route
            path="/admin/editeaza-ingredient/:Id"
            element={<EditIngredient />}
          />
        </Route>

        <Route path="/admin/sterge-ingredient">
          <Route
            path="/admin/sterge-ingredient/:Id"
            element={<DeleteIngredient />}
          />
        </Route>
        {/* ------------------------- PRODUSE -------------------------------- */}
        {/* ----------------------------------------------------------------- */}

        <Route path="/admin/lista-produse" element={<ProductList />} />
        <Route path="/admin/adauga-produs" element={<AddProduct />} />

        <Route path="/admin/sterge-produs/">
          <Route path="/admin/sterge-produs/:Id" element={<DeleteProduct />} />
        </Route>

        <Route path="/admin/editeaza-produs">
          <Route path="/admin/editeaza-produs/:Id" element={<EditProduct />} />
        </Route>

        {/* ----------------------------------------------------------------------CRUD CATEGORII ------------------------------------------------------------------ */}
        {/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */}

        <Route path="/admin/lista-categorii" element={<CategoryList />} />

        <Route path="/admin/adauga-categorie" element={<AddCategory />} />

        <Route path="/admin/editeaza-categorie">
          <Route
            path="/admin/editeaza-categorie/:Id"
            element={<EditCategory />}
          />
        </Route>

        <Route path="/admin/sterge-categorie">
          <Route
            path="/admin/sterge-categorie/:Id"
            element={
              <DeleteCategory
                categoryData={categoryData}
                modifiedData={modifiedData}
                setModifiedData={setModifiedData}
              />
            }
          />
        </Route>
      </Route>
      {/* -----------Aici se termina subramurile pt /admin--------------- */}
    </Routes>
  );
};

export default Views;
