import React from "react";
import FooterSvg from "./Svg/footer.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrap = styled.div`
  width: 100%;
  padding: 10px;
  //border-top: 1px solid rgba(0, 0, 0, 0.4);
  background-color: rgba(243, 218, 214, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.div`
  padding: 10px;
`;
const style = {
  FooterSvg: {
    width: "150px",
  },
};
const Footer = () => {
  return (
    <Wrap id="contact">
      {/* <Title>Cum se comandă:</Title> */}
      <Content>
        <img src={FooterSvg} style={style.FooterSvg} />
        {/* <Text>
          <div>
            Comenzile se fac numai prin mesaj privat pe facebook
            <Link
              to="https://www.facebook.com/LucilleNaturalCare/"
              style={{ marginLeft: "5px" }}
            >
              Lucille Natural Care.
            </Link>
          </div>
          <div>
            Vă rog specificați cazul dvs. în detaliu, de preferabil cu un scurt
            istoric medical, dacă este cazul.
          </div>
          <div>
            Livrare prin curier: 16ron cu plata în cont și 20ron cu ramburs.
          </div>
        </Text> */}
      </Content>
    </Wrap>
  );
};

export default Footer;
