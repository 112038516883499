import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Wrap = styled(Container)``;

const DeleteCategory = () => {
  const { categoryData, modifiedData, setModifiedData } = useOutletContext();
  const [name, setName] = useState("");
  const [path, setPath] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const categoryId = parseInt(params.Id);

  useEffect(() => {
    if (categoryData.length != 0) {
      const currentCategory = categoryData.find((item) => {
        return parseInt(item.Id) == parseInt(categoryId);
      });

      setName(currentCategory.Name);
      setPath(currentCategory.Path);
    }
  }, [categoryData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      method: "DELETE",
      body: JSON.stringify({ id: parseInt(categoryId, 10) }),
    };

    //  Corectez in baza de date
    fetch("categoriesCRUD.php", config)
      // .then((rezultat) => rezultat.text())
      // .then((rez) => console.log(rez));
      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-categorii"));
  };

  return (
    <div style={{ marginTop: "180px" }}>
      <Wrap>
        <form
          style={{ width: "70%", margin: "auto", marginTop: "70px" }}
          onSubmit={handleSubmit}
        >
          <h3>Șterge categorie. Sunteți sigur? </h3>
          <div className="mb-3 mt-3">
            <label> Nume</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label> Path</label>
            <input
              type="text"
              className="form-control"
              value={path}
              onChange={(event) => {
                setPath(event.target.value);
              }}
            />
          </div>

          <button type="submit" className="btn btn-danger w-25">
            <p>Șterge</p>
          </button>
        </form>
      </Wrap>
    </div>
  );
};

export default DeleteCategory;
