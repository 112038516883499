import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Wrap = styled(Container)`
  margin-top: 180px;
`;

const DeleteProduct = () => {
  const { productsData, modifiedData, setModifiedData } = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const productId = parseInt(params.Id);
  const product = productsData.find((item) => {
    return parseInt(item.Id) == parseInt(productId);
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      method: "DELETE",
      body: JSON.stringify({ id: parseInt(productId, 10) }),
    };

    fetch("productsCRUD.php", config)
      // .then((rezultat) => rezultat.text())
      // .then((rez) => console.log(rez));
      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-produse"));
  };
  return (
    <>
      {productsData && (
        <Wrap>
          <form
            style={{ width: "70%", margin: "auto", marginTop: "70px" }}
            onSubmit={handleSubmit}
          >
            <h3>Șterge produs. Sunteți sigur? </h3>
            <div className="mb-3 mt-3">
              <label> Id</label>
              <input
                type="text"
                className="form-control"
                value={productId}
                readOnly
              />
            </div>
            <div className="mb-3">
              <label> Nume</label>
              <input
                type="text"
                className="form-control"
                value={product.Name}
                readOnly
              />
            </div>

            <button type="submit" className="btn btn-danger w-25">
              <p>Șterge</p>
            </button>
          </form>
        </Wrap>
      )}
    </>
  );
};

export default DeleteProduct;
