import React, { useEffect } from "react";
import LogoSvg from "./Svg/home-page/home-page-logo.svg";
import MyDescriptionSvg from "./Svg/home-page/home-page-cine-sunt-eu.svg";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { StyleRoot } from "radium";
import { Container } from "react-bootstrap";

const Wrap = styled.div`
  //border: 1px solid red;
  width: 100%;
  margin-top: 140px;
  // height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageDiv = styled.div`
  //border: 4px solid black;
  width: 100%;
  //height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 30px;
  @media screen and (min-width: 1000px) {
  }
`;
const LucilleNaturalCare = styled.div`
  //border: 2px solid yellow;
  color: black;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 60px;
  font-weight: 400;
  text-shadow: 2px 2px grey;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-family: "Baloo Paaji 2", cursive;
  //font-family: "Great Vibes", cursive;
  //font-family: "Rye", cursive;

  @media screen and (min-width: 750px) {
    justify-content: center;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    font-size: 40px;
  }
`;
const Lucille = styled.div``;
const NaturalCare = styled.div``;
const CineSuntEuSection = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
  background-color: rgba(243, 218, 214, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CineSuntEuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
`;

const TitleSvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;
const MyDescriptionText = styled.div``;
const MyDescriptionFragment = styled.div`
  padding: 3px;
`;

const ImagesSection = styled.div`
  //border: 2px solid red;
  padding: 20px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImageSectionContent = styled.div`
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
  //   rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;
const ImageSectionText = styled(Container)`
  padding: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
`;
const GallerySection = styled.div`
  // border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 20px;
`;
const GalleryWrap = styled.div`
  width: 70%;
  //border: 2px solid blue;
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const style = {
  LogoImg: {
    maxWidth: "1000px",

    "@media (max-width:540px)": {
      maxWidth: "95%",
    },
  },
  MyDescriptionSvg: {
    width: "200px",
  },
  Image: {
    width: "150px",
    padding: "6px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
};

const HomePage = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <StyleRoot>
      <Wrap>
        {/* ---------------------------------Logo Image and Lucille Natural Care------------------------------ */}
        <ImageDiv>
          <img src={LogoSvg} style={style.LogoImg} />
          <LucilleNaturalCare>
            <Lucille>Lucille</Lucille>
            <NaturalCare>Natural Care</NaturalCare>
          </LucilleNaturalCare>
        </ImageDiv>
        {/* ---------------------------------Logo Image and Lucille Natural Care------------------------------*/}
        <CineSuntEuSection>
          <CineSuntEuContent>
            <TitleSvgDiv>
              <img
                src={MyDescriptionSvg}
                alt="eyes"
                style={style.MyDescriptionSvg}
              />
              <Title>Cine sunt eu?</Title>
            </TitleSvgDiv>
            <MyDescriptionText>
              <MyDescriptionFragment>
                <span style={{ opacity: "0" }}>Ba</span>O femeie care iubește să
                învețe de toate ! Deși formarea mea este una reală ( facultatea
                de Matematică la Univ. București), am avut o atracție mare către
                partea de medicină , astfel că am urmat în mod particular
                cursurile acestei facultăți, pentru ca mai apoi să intru în
                domeniul produselor cât mai naturale, indiferent că vorbim de
                alimentație sau de cosmetice.
              </MyDescriptionFragment>
              <MyDescriptionFragment>
                Am experimentat diferite stiluri de alimentație (vegetarian,
                vegan, am cochetat și cu keto ), pentru a mă fixa 15 ani mai
                târziu la un stil foarte echilibrat , combinat cu sport . Între
                timp am învățat să fac cam tot ce aveam nevoie prin casă, de la
                cosmetice la diferite tratamente pentru probleme dermatologice
                și chiar detergent de rufe.
              </MyDescriptionFragment>
              <MyDescriptionFragment>
                Acum câțiva ani m-a lovit o alta pasiune și anume programarea .
                Acest site a fost printre primele mele proiecte, la care cred că
                am ajuns la versiunea 15298 :)) .
              </MyDescriptionFragment>
              <MyDescriptionFragment>
                Este simplu, dar este al meu, făcut de la zero în React, fiind
                prezentat la lucrarea de dizertație, la terminarea cursurilor
                postuniversitare de programare, pe care le-am urmat la
                Politehnică.
              </MyDescriptionFragment>
            </MyDescriptionText>
          </CineSuntEuContent>
        </CineSuntEuSection>
        {/* -------------------------------------------IMAGES SECTION---------------------------- */}
        <ImagesSection>
          <ImageSectionContent>
            <Title>Câteva din săpunurile mele favorite</Title>
            <ImageSectionText>
              A fost o perioadă când eram fascinată de săpun, făceam unul la 2
              zile, experimentând cu rețete, forme și culori. A fost o joacă
              superbă și din acea joacă au ieșit aceste modele, colorate ori cu
              pudre naturale, ori cu pigment mineral. Am ajuns chiar să-mi fac
              propriile matrițe. Ce înseamnă să ai timp!
            </ImageSectionText>

            <GallerySection>
              <GalleryWrap>
                <img
                  src="/images/home-page-images-section/1.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/2.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/3.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/4.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/5.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/6.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/7.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/8.jpg"
                  style={style.Image}
                />
              </GalleryWrap>
              <GalleryWrap>
                <img
                  src="/images/home-page-images-section/a.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/b.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/c.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/d.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/e.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/f.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/g.jpg"
                  style={style.Image}
                />
                <img
                  src="/images/home-page-images-section/i.jpg"
                  style={style.Image}
                />
              </GalleryWrap>
            </GallerySection>
          </ImageSectionContent>
        </ImagesSection>
      </Wrap>
    </StyleRoot>
  );
};

export default HomePage;
