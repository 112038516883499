import React, { useRef } from "react";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import * as AiIcons from "react-icons/ai";
import DropdownMenu from "./DropdownMenu";
import sideNavSvg from "../Svg/side-nav/sideNavSvg.svg";

const FullPageWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  // ${({ sideNav }) => (!sideNav ? "left:100%;" : "right:0;")}
`;

const SideWrap = styled.div`
  //background-color: rgba(243, 218, 214, 0.8);
  background-color: rgba(0, 0, 0, 0.8);
  width: 300px;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`;
const MenuWrap = styled.div`
  //border: 2px solid red;
  height: 300px;
`;
const NavIconClose = styled.div`
  font-size: 2em;
  //color: black;
  color: rgba(243, 218, 214, 1);
  margin-top: 10px;
  margin-left: auto;
  margin-right: 10px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem;
  cursor: pointer;
  //color: black;
  color: rgba(243, 218, 214, 1);
  font-weight: 500;
  //border: 2px solid green;
`;

const NavLinkItem = styled.div`
  padding: 15px;
  //border: 2px solid blue;
`;

const style = {
  MenuItemSvg: {
    width: "30px",
  },
};
const SideNav = ({
  showSideNav,
  sideNav,
  menuItems,
  menuItemClicked,
  setMenuItemClicked,
  categoryData,
  dropdownActive,
  setDropdownActive,
}) => {
  const refIconClose = useRef();
  return (
    <>
      <FullPageWrap
        style={{ left: !sideNav ? "100%" : null, right: !sideNav ? null : "0" }}
      >
        <SideWrap>
          {/* -------------X-ul de inchidere a side nav---------------- */}
          <NavIconClose onClick={showSideNav} to="#" id={"NavIconCloseId"}>
            <AiIcons.AiOutlineClose />
          </NavIconClose>
          {/* -------------Meniul din laterala ---------------- */}

          <MenuWrap>
            {menuItems.map((elem) => {
              if (elem.hasdropdown == "yes") {
                const elemDropdownItems = categoryData.filter(
                  (dropdownItem) => {
                    return dropdownItem.MenuItem == elem.Name;
                  }
                );

                return (
                  <NavLinkItem
                    key={elem.Id}
                    onClick={() => {
                      setDropdownActive(!dropdownActive);
                      setMenuItemClicked(elem.Id);
                    }}
                  >
                    <img src={sideNavSvg} style={style.MenuItemSvg} />

                    <NavLink to={elem.Path}>{elem.Name}</NavLink>
                    {elem.hasdropdown == "yes" && (
                      <AiIcons.AiFillCaretDown
                        style={{ color: "rgba(243, 218, 214, 1)" }}
                      />
                    )}
                    {elem.hasdropdown == "yes" &&
                      menuItemClicked == elem.Id &&
                      dropdownActive == true && (
                        <DropdownMenu
                          sideNav={sideNav}
                          elemDropdownItems={elemDropdownItems}
                          menuItemClicked={menuItemClicked}
                          setMenuItemClicked={setMenuItemClicked}
                          showSideNav={showSideNav}
                          setDropdownActive={setDropdownActive}
                          dropdownActive={dropdownActive}
                          refIconClose={refIconClose}
                        />
                      )}
                  </NavLinkItem>
                );
              } else {
                return (
                  <NavLinkItem onClick={showSideNav} key={elem.Id}>
                    <img src={sideNavSvg} style={style.MenuItemSvg} />
                    <NavLink to={elem.Path} key={elem.Id}>
                      {elem.Name}
                    </NavLink>
                  </NavLinkItem>
                );
              }
            })}
          </MenuWrap>
        </SideWrap>
      </FullPageWrap>
    </>
  );
};

export default SideNav;
