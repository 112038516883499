import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import AdminMenu from "../AdminMenu";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";

const Wrap = styled(Container)``;

const EditCategory = () => {
  const {
    categoryData,
    modifiedData,
    setModifiedData,
    menuItems,
    currentMenuItemIds,
  } = useOutletContext();
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [selectedMenuItemIds, setSelectedMenuItemIds] =
    useState(currentMenuItemIds);

  const navigate = useNavigate();
  const params = useParams();
  const categoryId = parseInt(params.Id);

  //******************************Am retinut in selectedCategoriesIds id-urile categoriilor selectate************************************************* */
  //**************************************************************************************************************************************************** */

  //Formez un vector de tipul [{value:"",label:""}]
  const options = [];
  menuItems.forEach((item) => {
    const option = {
      value: `${item.Id}`,
      label: `${item.Name}`,
    };
    options.push(option);
  });
  // handle onChange event of the dropdown
  const handleMenuItemChange = (e) => {
    setSelectedMenuItemIds(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  useEffect(() => {
    if (categoryData.length != 0) {
      const currentCategory = categoryData.find((item) => {
        return parseInt(item.Id) == parseInt(categoryId);
      });

      setName(currentCategory.Name);
      setPath(currentCategory.Path);
    }
  }, [categoryData]);

  //   ------------------------------HANDLE SUBMIT----------------------------
  const handleSubmit = (e) => {
    e.preventDefault();

    const category = {
      Id: parseInt(categoryId),
      Name: name,
      Path: path,
      SelectedMenuItemIds: selectedMenuItemIds[0],
    };

    const config = {
      method: "PATCH",
      body: JSON.stringify(category),
    };

    //  Corectez in baza de date
    fetch("categoriesCRUD.php", config)
      // .then((rezultat) => rezultat.text())
      // .then((rez) => console.log(rez));
      .then(() => {
        setModifiedData(!modifiedData);
      })
      .then(() => navigate("/admin/lista-categorii"));
  };
  return (
    <div style={{ marginTop: "180px" }}>
      <AdminMenu />
      <Wrap>
        <form
          style={{ width: "70%", margin: "auto", marginTop: "70px" }}
          onSubmit={handleSubmit}
        >
          <h3>Editează categorie</h3>
          <div className="mb-3 mt-3">
            <label> Nume</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label> Path</label>
            <input
              type="text"
              className="form-control"
              value={path}
              onChange={(event) => {
                setPath(event.target.value);
              }}
            />
          </div>
          {/* ************************************Select List pt Menu Item********************************* */}
          <div className="mb-3">
            <label className="form-label text-danger">
              Alegeti intrarea din meniu:
            </label>
            <Select
              className="dropdown"
              placeholder="Select Option"
              value={options.filter((obj) =>
                selectedMenuItemIds.includes(obj.value)
              )} // set selected values
              options={options} // set list of the data
              onChange={handleMenuItemChange} // assign onChange function
              isMulti
              isClearable
            />
          </div>

          <button type="submit" className="btn btn-info w-25">
            <p>Editează</p>
          </button>
        </form>
      </Wrap>
    </div>
  );
};

export default EditCategory;
