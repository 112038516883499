import React from "react";
import styled from "styled-components";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

const style = {
  arrow: {
    color: "rgba(47,86,111,255)",
    position: "fixed",
    top: "50%",
    right: "15%",
    transform: "translateX(120%)",
    zIndex: "1",
    cursor: "pointer",
    fontSize: "40px",
    "@media (min-width: 690px)": {
      dislay: "none",
    },
  },
};
const TopButton = () => {
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <FaRegArrowAltCircleUp style={style.arrow} onClick={goTop} />
    </>
  );
};

export default TopButton;
