import React, { useEffect, useState } from "react";
import SingleCard from "./SingleCard";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Svg from "./Svg/productlistpage-svg.svg";
import TopButton from "./TopButton";

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CategoryTitle = styled.div`
  text-align: center;
  padding: 1rem;
  font-size: 25px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
`;
const CategoryDescription = styled(Container)`
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
`;

const DescriptionDiv = styled.div`
  width: 100%;
  border-radius: 7px;
`;
const DescriptionItem = styled.div`
  padding: 7px;
  font-weight: 500;
`;
const BackgroundMask = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -99;
  top: 110px;
  background-color: rgba(0, 0, 0, 0.4);
`;
const BackgroundImage = styled.div`
  background-image: url(${Svg});
  position: fixed;
  width: 100%;
  height: calc(100vh - 140px);
  z-index: -100;
  top: 140px;
`;

const ContainerWrap = styled.div`
  width: 70%;
  margin-top: 170px;
  margin-bottom: 50px;
  background-color: rgba(243, 218, 214, 0.6);
  border-radius: 25px;
  padding: 15px;
`;

const ProductListPage = ({
  category,
  productsData,
  categoryDescriptionsData,
  pricesbyquantityData,
  modifiedData,
  product_categoryData,
  productSpecificationsData,
  categoryData,
}) => {
  const [currentProductsIds, setCurrentProductsIds] = useState([]);
  const [products, setProducts] = useState([]);
  const [categoryDescriptions, setCategoryDescriptions] = useState([]);

  //--------------------Gasim produsele din categoria curenta---------------------------
  //------------------------------------------------------------------------------------

  //Gasim vectorul de id-uri ale produselor din categoria curenta:
  useEffect(() => {
    if (product_categoryData && category) {
      const currProductsIds = product_categoryData
        .filter((item) => {
          return item.Category_Id == category.Id;
        })
        .map((elem) => {
          return elem.Product_Id;
        });
      setCurrentProductsIds(currProductsIds);
    }
  }, [product_categoryData, category, modifiedData]);

  //Gasim produsele cu id-urile in acest vector
  useEffect(() => {
    if (productsData && currentProductsIds) {
      const pr = productsData.filter((item) => {
        return currentProductsIds.includes(item.Id) == true;
      });
      setProducts(pr);
    }
  }, [currentProductsIds, productsData, modifiedData]);

  // ------------------Gasim descrierile pt category_Id-----------------------------------
  //--------------------------------------------------------------------------------------
  useEffect(() => {
    if (categoryDescriptions && category) {
      const cat = categoryDescriptionsData.filter((item) => {
        return item.Category_Id == category.Id;
      });
      setCategoryDescriptions(cat);
    }
  }, [category, modifiedData]);

  return (
    <>
      {category && products && (
        <Wrap>
          <TopButton />
          <BackgroundMask />
          <BackgroundImage />
          <ContainerWrap>
            <DescriptionDiv>
              <CategoryTitle>{category.Name}</CategoryTitle>
              {/* <CategoryDescription>
                {categoryDescriptions &&
                  categoryDescriptions.map((item) => {
                    return (
                      <DescriptionItem key={item.Id}>
                        {item.Description}
                      </DescriptionItem>
                    );
                  })}
              </CategoryDescription> */}
            </DescriptionDiv>
            <Row xs="auto" className="justify-content-center">
              {products.map((item) => {
                return (
                  <Col key={item.Id}>
                    {item.Visibility == "Vizibil" && (
                      <SingleCard
                        product={item}
                        pricesbyquantityData={pricesbyquantityData}
                        modifiedData={modifiedData}
                        productSpecificationsData={productSpecificationsData}
                        product_categoryData={product_categoryData}
                        categoryData={categoryData}
                      />
                    )}
                  </Col>
                );
              })}
            </Row>
          </ContainerWrap>
        </Wrap>
      )}
    </>
  );
};

export default ProductListPage;
