import React, { useState } from "react";
import styled from "styled-components";
import AdminMenu from "./AdminMenu";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const LoginWrap = styled(Container)`
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LoginDiv = styled.div`
  width: 300px;
  height: 500px;
  border: 2px solid rgba(226, 215, 161, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const AdminSection = styled(Container)``;

const LoginPage = ({
  logged,
  setLogged,
  ingredientsData,
  productSearchableList,
  setProductSearchableList,
  ingredientSearchableList,
  setIngredientSearchableList,
  onSearchBarTextChange,
  modifiedData,
  setModifiedData,
  productsData,
  product_categoryData,
  categoryData,
  productDescriptionsData,
  product_ingredientData,
  pricesbyquantityData,
  findCurrentProduct,
  productSpecificationsData,
  productImagesFolderPath,
  currentProduct,
  currentProductCategoryIds,
  currentProductDescriptions,
  currentProductIngredientIds,
  currentPricesByQuantity,
  currentProductSpecifications,
  menuItems,
  findCurrentMenuItemIds,
  currentMenuItemIds,
  filterTextProductList,
  setFilterTextProductList,
  filterTextIngredientList,
  setFilterTextIngredientList,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username == "verysmartbunny!85" && password == "sofuckingproud!85") {
      setLogged(true);
    } else {
      if (username != "verysmartbunny!85") {
        alert("Username gresit");
      }
      if (password != "sofuckingproud!85") {
        alert("Parola gresita");
      }
    }
  };

  return (
    <LoginWrap>
      {!logged && (
        <LoginDiv>
          <div className="form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <h3>LOGIN</h3>

              <div className="mb-3">
                <label>Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
              </div>
              <div className="mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder=""
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-warning">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </LoginDiv>
      )}

      {logged && (
        <AdminSection>
          <AdminMenu />
          <Outlet
            context={{
              productSearchableList,
              setProductSearchableList,
              ingredientSearchableList,
              setIngredientSearchableList,
              ingredientsData,
              onSearchBarTextChange,
              modifiedData,
              setModifiedData,
              productsData,
              product_categoryData,
              categoryData,
              productDescriptionsData,
              product_ingredientData,
              pricesbyquantityData,
              productSpecificationsData,
              productImagesFolderPath,
              currentProduct,
              findCurrentProduct,
              currentProductCategoryIds,
              currentProductDescriptions,
              currentProductIngredientIds,
              currentPricesByQuantity,
              currentProductSpecifications,
              menuItems,
              findCurrentMenuItemIds,
              currentMenuItemIds,
              filterTextProductList,
              setFilterTextProductList,
              filterTextIngredientList,
              setFilterTextIngredientList,
            }}
          />
        </AdminSection>
      )}
    </LoginWrap>
  );
};

export default LoginPage;
