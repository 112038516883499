import { StyleRoot } from "radium";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer.js";
import Views from "./Components/Views";

const ContainerWrap = styled.div`
  font-family: "Poppins", sans-serif;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

function App() {
  const [sideNav, setSideNav] = useState(false);
  const showSideNav = () => {
    setSideNav(!sideNav);
  };

  const [logged, setLogged] = useState(false);
  const [modifiedData, setModifiedData] = useState(false);
  const [filterTextProductList, setFilterTextProductList] = useState("");
  const [filterTextIngredientList, setFilterTextIngredientList] = useState("");
  const [productSearchableList, setProductSearchableList] = useState([]);
  const [ingredientSearchableList, setIngredientSearchableList] = useState([]);
  const [productImagesFolderPath, setProductImagesFolderPath] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [product_categoryData, setProduct_categoryData] = useState([]);
  const [productDescriptionsData, setProductDescriptionsData] = useState([]);
  const [categoryDescriptionsData, setCategoryDescriptionsData] = useState([]);
  const [pricesbyquantityData, setPricesbyquantityData] = useState([]);
  const [ingredientsData, setIngredientsData] = useState([]);
  const [product_ingredientData, setProduct_ingredientData] = useState([]);
  const [productSpecificationsData, setProductSpecificationsData] = useState(
    []
  );

  //******************************REFRESHING PAGE ONLY ONCE ************************************************* */
  //************************************************************************************************************************ */
  // const reloadCount = sessionStorage.getItem("reloadCount");
  // useEffect(() => {
  //   if (reloadCount < 2) {
  //     sessionStorage.setItem("reloadCount", String(reloadCount + 1));
  //     window.location.reload();
  //   } else {
  //     sessionStorage.removeItem("reloadCount");
  //   }
  // }, []);

  //******************************PRODUCT SEARCH IN ADMIN->PRODUCT LIST************************************************* */
  //************************************************************************************************************************ */
  const onSearchBarTextChange = (text, list) => {
    const resultList = list.filter((item) => {
      return item.Name.toLowerCase().includes(text.toLowerCase());
    });

    if (list == productsData) {
      setProductSearchableList(resultList);
    }
    if (list == ingredientsData) {
      setIngredientSearchableList(resultList);
    }
  };
  // --Pt actualizarea searchableList atunci cand se schimba vizibilitatea unui produs--------
  useEffect(() => {
    onSearchBarTextChange(filterTextProductList, productsData);
  }, [productsData]);
  useEffect(() => {
    onSearchBarTextChange(filterTextIngredientList, ingredientsData);
  }, [ingredientsData]);

  //******************************GASESC MENU ITEM-URILE PT CATEGPRIA CURENTA************************************************* */
  //************************************************************************************************************************** */
  const [currentMenuItemIds, setCurrentMenuItemIds] = useState([]);
  const findCurrentMenuItemIds = (categoryName) => {
    const currMenuItems = categoryData
      .filter((item) => {
        return categoryName == item.Name;
      })
      .map((el) => {
        return el.MenuItem;
      });

    setCurrentMenuItemIds(currMenuItems);
  };

  //******************************GASESC PRODUSUL CURENT************************************************* */
  //**************************************************************************************************************************************************** */

  const [currentProduct, setCurrentProduct] = useState();
  const [currentProductCategoryIds, setCurrentProductCategoryIds] = useState();
  const [currentProductDescriptions, setCurrentProductDescriptions] =
    useState();
  const [currentProductIngredientIds, setCurrentProductIngredientIds] =
    useState();
  const [currentPricesByQuantity, setCurrentPricesByQuantity] = useState();
  const [currentProductSpecifications, setCurrentProductSpecifications] =
    useState();

  const findCurrentProduct = (id) => {
    const currProduct = productsData.find((item) => {
      return parseInt(item.Id) == parseInt(id);
    });

    const currProductCategoriesIds = product_categoryData
      .filter((item) => {
        return parseInt(item.Product_Id) == parseInt(id);
      })
      .map((el) => {
        return el.Category_Id;
      });

    const currProductDescriptions = productDescriptionsData
      .filter((item) => {
        return parseInt(item.Product_Id) == parseInt(id);
      })
      .map((elem) => {
        return elem.Description;
      });
    const currProductIngredientIds = product_ingredientData
      .filter((item) => {
        return parseInt(item.Product_Id) == parseInt(id);
      })
      .map((el) => {
        return el.Ingredient_Id;
      });
    const currPricesByQuantity = pricesbyquantityData.filter((item) => {
      return parseInt(item.Product_Id) == parseInt(id);
    });
    const currProductSpecifications = productSpecificationsData
      .filter((item) => {
        return parseInt(item.Product_Id) == parseInt(id);
      })
      .map((elem) => {
        return elem.Specification;
      });

    setCurrentProduct(currProduct);
    setCurrentProductCategoryIds(currProductCategoriesIds);
    setCurrentProductDescriptions(currProductDescriptions);
    setCurrentProductIngredientIds(currProductIngredientIds);
    setCurrentPricesByQuantity(currPricesByQuantity);
    setCurrentProductSpecifications(currProductSpecifications);
  };

  //---------------------------PRODUCT IMAGES FOLDER PATH--------------------------
  useEffect(() => {
    fetch("getProductImagesFolderPath.php")
      .then((response) => {
        return response.json();
      })
      // .then((response) => console.log(response))
      .then((result) => setProductImagesFolderPath(result));
    // .then((response) => response.text())
  }, [modifiedData]);

  //---------------------------MENU ITEMS--------------------------
  useEffect(() => {
    fetch("menuItemsCRUD.php")
      .then((response) => {
        return response.json();
      })
      .then((result) => setMenuItems(result));
    // .then((response) => response.text())
    // .then((response) => console.log(response));
  }, [modifiedData]);

  //-------------------------PRODUCTS------------------------------
  useEffect(() => {
    fetch("productsCRUD.php")
      .then((response) => {
        return response.json();
      })
      .then((result) => setProductsData(result));
    // .then((response) => response.text())
    // .then((response) => console.log(response));
  }, [modifiedData]);

  //-------------------------CATEGORIES------------------------------
  useEffect(() => {
    fetch("categoriesCRUD.php")
      .then((response) => {
        return response.json();
      })
      .then((result) => setCategoryData(result));
    // .then((response) => response.text())
    // .then((response) => console.log(response));
  }, [modifiedData]);

  // -------------------PRODUCT_CATEGORY TABEL--------------------------------

  useEffect(() => {
    fetch("product_categoryCRUD.php")
      .then((response) => {
        return response.json();
      })
      // .then((response) => {
      //   console.log(response)
      // })
      .then((result) => setProduct_categoryData(result));
  }, [modifiedData]);
  // console.log(product_categoryData)
  //-------------------------PRODUCT DESCRIPTIONS------------------------------
  useEffect(() => {
    fetch("product_descriptionsCRUD.php")
      .then((response) => {
        return response.json();
      })
      .then((result) => setProductDescriptionsData(result));
    // .then((response) => response.text())
    // .then((response) => console.log(response));
  }, [modifiedData]);

  //-------------------------CATEGORY DESCRIPTIONS------------------------------
  useEffect(() => {
    fetch("category_descriptionsCRUD.php")
      .then((response) => {
        return response.json();
      })
      .then((result) => setCategoryDescriptionsData(result));
  }, [modifiedData]);

  //-------------------------PRICES BY QUANTITY------------------------------
  useEffect(() => {
    fetch("pricesbyquantityCRUD.php")
      .then((response) => {
        return response.json();
      })
      .then((result) => setPricesbyquantityData(result));
  }, [modifiedData]);

  // ---------------------------------INGREDIENTS--------------------------------
  useEffect(() => {
    fetch("ingredientsCRUD.php")
      .then((response) => {
        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        return response.json();
      })
      // .then((response) => {
      //   console.log(response);
      // })
      .then((result) => setIngredientsData(result))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [modifiedData]);

  // ---------------------------------PRODUCT_INGREDIENT DATA--------------------------------
  useEffect(() => {
    fetch("product_ingredientCRUD.php")
      .then((response) => {
        return response.json();
      })
      // .then((response) => {
      //   console.log(response)
      // })
      .then((result) => setProduct_ingredientData(result));
  }, [modifiedData]);
  // ---------------------------------PRODUCT specifications DATA--------------------------------
  useEffect(() => {
    fetch("product_specificationsCRUD.php")
      .then((response) => {
        return response.json();
      })
      // .then((response) => {
      //   console.log(response)
      // })
      .then((result) => setProductSpecificationsData(result));
  }, [modifiedData]);

  return (
    <StyleRoot>
      <ContainerWrap>
        <Header
          sideNav={sideNav}
          showSideNav={showSideNav}
          menuItems={menuItems}
          categoryData={categoryData}
        />

        <Views
          menuItems={menuItems}
          logged={logged}
          setLogged={setLogged}
          modifiedData={modifiedData}
          setModifiedData={setModifiedData}
          productsData={productsData}
          categoryData={categoryData}
          product_categoryData={product_categoryData}
          productDescriptionsData={productDescriptionsData}
          categoryDescriptionsData={categoryDescriptionsData}
          pricesbyquantityData={pricesbyquantityData}
          ingredientsData={ingredientsData}
          product_ingredientData={product_ingredientData}
          productImagesFolderPath={productImagesFolderPath}
          findCurrentProduct={findCurrentProduct}
          currentProduct={currentProduct}
          currentProductCategoryIds={currentProductCategoryIds}
          currentProductDescriptions={currentProductDescriptions}
          currentProductIngredientIds={currentProductIngredientIds}
          currentPricesByQuantity={currentPricesByQuantity}
          productSpecificationsData={productSpecificationsData}
          currentProductSpecifications={currentProductSpecifications}
          productSearchableList={productSearchableList}
          setProductSearchableList={setProductSearchableList}
          ingredientSearchableList={ingredientSearchableList}
          setIngredientSearchableList={setIngredientSearchableList}
          onSearchBarTextChange={onSearchBarTextChange}
          findCurrentMenuItemIds={findCurrentMenuItemIds}
          currentMenuItemIds={currentMenuItemIds}
          filterTextProductList={filterTextProductList}
          setFilterTextProductList={setFilterTextProductList}
          filterTextIngredientList={filterTextIngredientList}
          setFilterTextIngredientList={setFilterTextIngredientList}
        />

        {!logged && <Footer />}
      </ContainerWrap>
    </StyleRoot>
  );
}

export default App;
