import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const cardStyle = {
  card: {
    width: "220px",
    borderRadius: "10px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    "&:hover": {
      transform: "scale(1.02)",
      color: "red",
    },
  },
  cardImage: {
    marginTop: "13px",
    width: "150px",
    height: "200px",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: "16px",
    color: "rgba(143, 30, 53, 255)",
    width: "90%",
  },
  cardSubtitle: {
    fontSize: "14px",
    width: "100%",
  },
};
const CardImage = styled(Link)``;
const DetaliiButton = styled(Link)`
  width: 100%;
  height: 50px;
  background-color: rgba(143, 30, 53, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-weight: 600;
  font-size: 17px;
  color: rgba(143, 30, 53, 255);
`;
const Quantity = styled.div`
  padding: 3px;
`;

const SingleCard = ({
  product,
  pricesbyquantityData,
  productSpecificationsData,
  product_categoryData,
  categoryData,
}) => {
  const pricesMap = [];
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [productPricesByQuantities, setProductPricesByQuantities] = useState(
    []
  );
  const [
    orderedPricesByQuantityAfterQuantity,
    setOrderedPricesByQuantityAfterQuantity,
  ] = useState([]);
  const [productSpecifications, setProductSpecifications] = useState([]);
  const [soapCategoryId, setSoapCategoryId] = useState(0);
  const [isSoap, setIsSoap] = useState(false);

  //-----------Selectez toate variantele de preturi si cantitati pt acest produs-----------
  //---------------------------------------------------------------------------------------
  useEffect(() => {
    const prPricesByQuantities = pricesbyquantityData.filter((item) => {
      return item.Product_Id == product.Id;
    });
    setProductPricesByQuantities(prPricesByQuantities);
    // console.log(prPricesByQuantities);
  }, [pricesbyquantityData]);

  //-------------Functie de ordonare a unui vector de obiecte dupa cantitate---------------
  //---------------------------------------------------------------------------------------
  function orderByQuantity(vector) {
    const orderedVector = [...vector].sort((a, b) => a.Quantity - b.Quantity);
    return orderedVector;
  }

  //----------Am ordonat obiectele din pricesbyquantity table dupa cantitate-----------------
  //---------------------------------------------------------------------------------------
  useEffect(() => {
    if (productPricesByQuantities) {
      const orderedByQuantity = orderByQuantity(productPricesByQuantities);
      setOrderedPricesByQuantityAfterQuantity(orderedByQuantity);
    }
  }, [productPricesByQuantities]);

  //------------Formez obiectul pricesMap de tip {gramaj:pret,gramaj:pret,etc}--------------
  //---------------------------------------------------------------------------------------

  orderedPricesByQuantityAfterQuantity.forEach((item) => {
    pricesMap[item.Quantity] = item.Price;
  });
  useEffect(() => {
    if (orderedPricesByQuantityAfterQuantity.length != 0) {
      setSelectedQuantity(orderedPricesByQuantityAfterQuantity[0].Quantity);
    }
  }, [orderedPricesByQuantityAfterQuantity]);

  //---------------------HANDLE QUANTITY CHANGE IN INPUT--------------------------------------
  //---------------------------------------------------------------------------------------
  const handleQuantityChange = (event) => {
    setSelectedQuantity(parseInt(event.target.value, 10));
  };
  //----------------ASOCIEZ PRETUL AFERENT CANTITATII ALESE--------------------------------
  //---------------------------------------------------------------------------------------
  const selectedPrice = pricesMap[selectedQuantity];

  //----------------GASESC TOATE SPECIFICATIILE PRODUSULUI--------------------------------
  //---------------------------------------------------------------------------------------
  useEffect(() => {
    if (product) {
      const prSpecifications = productSpecificationsData.filter((item) => {
        return parseInt(item.Product_Id) == parseInt(product.Id);
      });

      setProductSpecifications(prSpecifications);
    }
  }, [productSpecificationsData]);

  //--------Gasesc categoriile din care face parte produsul, daca este in Sapunuri, atunci alegem g in loc de ml
  //---Gasesc prima oara Id-ul categoriei Sapunuri:
  useEffect(() => {
    const soapCat = categoryData.find((item) => {
      return item.Name == "Săpunuri naturale";
    });

    setSoapCategoryId(soapCat.Id);
  }, [categoryData]);
  console.log(soapCategoryId);
  useEffect(() => {
    if (product) {
      const currProduct_Category = product_categoryData.filter((item) => {
        return item.Product_Id == product.Id;
      });
      console.log(currProduct_Category);
      currProduct_Category.map((item) => {
        if (item.Category_Id == soapCategoryId) {
          setIsSoap(true);
        }
      });
    }
  }, [product_categoryData, product]);

  return (
    <>
      {product && (
        <Card style={cardStyle.card}>
          <CardImage to={`/produse/${product.Id}`}>
            <Card.Img
              className=" hover-shadow"
              style={cardStyle.cardImage}
              variant="top"
              src={product.Image}
            />
          </CardImage>
          <Card.Body style={cardStyle.body}>
            <Card.Title style={cardStyle.cardTitle}>{product.Name}</Card.Title>
            <ul style={cardStyle.cardSubtitle}>
              {productSpecifications &&
                productSpecifications.map((item) => {
                  return <li key={item.Id}>{item.Specification}</li>;
                })}
            </ul>
            <Price>
              {selectedPrice}
              <div style={{ marginLeft: "1px" }}>RON</div>
            </Price>
            <Quantity>
              <select
                id="quantity"
                onChange={handleQuantityChange}
                value={selectedQuantity}
                style={{
                  borderRadius: "4px",
                  padding: "5px 8px",
                  marginLeft: "6px",
                }}
              >
                {Object.keys(pricesMap).map((quantity) => (
                  <option key={quantity} value={quantity}>
                    {quantity}
                    {isSoap == true && <p>g</p>}
                    {isSoap == false && <p>ml</p>}
                  </option>
                ))}
              </select>
            </Quantity>
          </Card.Body>

          <DetaliiButton to={`/produse/${product.Id}`}>Detalii</DetaliiButton>
        </Card>
      )}
    </>
  );
};

export default SingleCard;
