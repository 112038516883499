import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { NavLink as Link } from "react-router-dom";

const AdminWrap = styled(Container)``;

const AdminNav = styled(Container)`
  width: 60%;
  margin-top: 20px;
`;
const LinkWrap = styled.div`
  border: 2px solid rgba(226, 215, 161, 255);
  background-color: rgba(190, 190, 190, 0.1);
  margin-bottom: 10px;
  text-align: center;
`;
const NavLink = styled(Link)`
  text-decoration: none;
  padding: 0.5rem;
  height: 100%;
  cursor: pointer;
  color: rgba(190, 190, 190, 1);
  font-weight: 600;

  &.active {
    color: rgba(125, 125, 125, 255);
  }
  &:hover {
    color: rgba(102, 123, 207, 255);
  }
  @media screen and (min-width: 1300px) {
    font-size: 15px;
  }
`;
const Title = styled.div`
  color: rgba(143, 30, 53, 255);
  font-weight: 500;
`;

const AdminMenu = () => {
  return (
    <AdminWrap>
      <h3 className="text-center">Admin Menu</h3>
      <AdminNav>
        <Title className="text-center ">PRODUSE</Title>
        <LinkWrap>
          <NavLink to="/admin/lista-produse">Listă produse</NavLink>
        </LinkWrap>
        <LinkWrap>
          <NavLink to="/admin/adauga-produs">Adaugă produs</NavLink>
        </LinkWrap>
        <Title className="text-center ">CATEGORII</Title>
        <LinkWrap>
          <NavLink to="/admin/lista-categorii">Listă categorii</NavLink>
        </LinkWrap>
        <LinkWrap>
          <NavLink to="/admin/adauga-categorie">Adaugă categorie</NavLink>
        </LinkWrap>
        <Title className="text-center ">INGREDIENTE</Title>
        <LinkWrap>
          <NavLink to="/admin/lista-ingrediente">Listă ingrediente</NavLink>
        </LinkWrap>
        <LinkWrap>
          <NavLink to="/admin/adauga-ingredient">Adaugă ingredient</NavLink>
        </LinkWrap>
      </AdminNav>
    </AdminWrap>
  );
};

export default AdminMenu;
