import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";
import TopButton from "../../TopButton";
import SearchBar from "../../SearchBar";
import Table from "react-bootstrap/Table";

const Wrap = styled(Container)`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

const ProductListContainer = styled(Container)`
  width: 100%;
  @media screen and (max-width: 650px) {
    font-size: 13px;
  }
`;
const ProductContainer = styled(Container)`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 15px;
  margin-top: 20px;
`;
const BtnsContainer = styled.div`
  //border: 2px solid red;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
`;

const Btn = styled.button`
  width: 90px;
  height: 30px;
  border-radius: 5px;
  @media screen and (max-width: 650px) {
    width: 70px;
  }
`;

const ProductList = () => {
  const [list, setList] = useState([]);
  const [productIdToChangeVisibility, setProductIdToChangeVisibility] =
    useState(0);

  const [currentProductVisibilityStatus, setCurrentProductVisibilityStatus] =
    useState("");
  const {
    productsData,
    product_categoryData,
    categoryData,
    productDescriptionsData,
    product_ingredientData,
    pricesbyquantityData,
    findCurrentProduct,
    productSpecificationsData,
    ingredientsData,
    productSearchableList,
    onSearchBarTextChange,
    modifiedData,
    setModifiedData,
    filterTextProductList,
    setFilterTextProductList,
  } = useOutletContext();

  useEffect(() => {
    if (productSearchableList.length == 0) {
      setList(productsData);
    } else {
      setList(productSearchableList);
    }
  }, [productSearchableList, productsData]);

  const handleVisibilityBtnClick = (e) => {
    e.preventDefault();

    const element = {
      productIdToChangeVisibility: productIdToChangeVisibility,
      currentProductVisibilityStatus: currentProductVisibilityStatus,
    };

    const config = {
      method: "PATCH",
      body: JSON.stringify(element),
    };
    fetch("changeProductVisibility.php", config)
      // .then((response) => response.text())
      // .then((response) => {
      //   console.log(response);
      // })
      .then(() => setModifiedData(!modifiedData));
  };
  return (
    <>
      <Wrap>
        <TopButton />
        <SearchBar
          filterText={filterTextProductList}
          setFilterText={setFilterTextProductList}
          list={productsData}
          onSearchBarTextChange={onSearchBarTextChange}
        />

        <ProductListContainer>
          {list &&
            list.map((item) => {
              //Gasesc id-urile categoriilor din care face parte produsul
              const itemCategoriesIds = product_categoryData
                .filter((el) => {
                  return el.Product_Id == item.Id;
                })
                .map((elem) => {
                  return elem.Category_Id;
                });

              //Gasesc categoriile cu id-urile in vectorul de msi sus
              const itemCategories = categoryData.filter((el) => {
                return itemCategoriesIds.includes(el.Id) == true;
              });

              //Gasesc descrierile produsului
              const itemDescriptions = productDescriptionsData.filter((el) => {
                return el.Product_Id == item.Id;
              });
              //Gasesc id-urile ingredientelor descrise la acest produs
              const itemDescribedIngredientsIds = product_ingredientData
                .filter((el) => {
                  return el.Product_Id == item.Id;
                })
                .map((elem) => {
                  return elem.Ingredient_Id;
                });
              //Gasesc ingredientele cu id-urile in vectorul de mai sus
              const describedIngredients = ingredientsData.filter((el) => {
                return itemDescribedIngredientsIds.includes(el.Id) == true;
              });
              //Gasesc liniile din pricesbyquantity pt produsul curent
              const itemPricesByQuantity = pricesbyquantityData.filter((el) => {
                return el.Product_Id == item.Id;
              });
              //Gasesc specificatiile produsului curent
              const itemSpecifications = productSpecificationsData.filter(
                (el) => {
                  return parseInt(el.Product_Id) == parseInt(item.Id);
                }
              );

              return (
                <ProductContainer key={item.Id}>
                  <BtnsContainer>
                    <Btn style={{ backgroundColor: "rgba(184,15,10,1)" }}>
                      <Link
                        to={`/admin/sterge-produs/${item.Id}`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Șterge
                      </Link>
                    </Btn>
                    <Btn style={{ backgroundColor: " rgba(255,215,0,1)" }}>
                      <Link
                        to={`/admin/editeaza-produs/${item.Id}`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                        onClick={() => findCurrentProduct(item.Id)}
                      >
                        Editează
                      </Link>
                    </Btn>

                    <form onSubmit={handleVisibilityBtnClick}>
                      <Btn
                        type="submit"
                        style={{
                          backgroundColor:
                            item.Visibility == "Vizibil"
                              ? "rgb(50,205,50)"
                              : "red",
                          color: "white",
                        }}
                        onClick={() => {
                          setProductIdToChangeVisibility(item.Id);
                          setCurrentProductVisibilityStatus(item.Visibility);
                        }}
                      >
                        {item.Visibility}
                      </Btn>
                    </form>
                  </BtnsContainer>
                  <Table responsive className="mt-2">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Imagine</th>
                        <th scope="col">Nume</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={item.Id}>
                        <th scope="row">{item.Id}</th>
                        <th>
                          <img src={item.Image} style={{ width: "60px" }} />
                        </th>
                        <td>{item.Name}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="mt-2">
                    <thead>
                      <tr>
                        <th scope="col">Categoria</th>
                        <th scope="col">Valabilitate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {itemCategories.map((el) => {
                            return <div key={el.Id}>{el.Name}</div>;
                          })}
                        </td>
                        <td>{item.Valability}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="mt-2">
                    <thead>
                      <tr>
                        <th scope="col">Ingred.descrise</th>
                        <th scope="col">Pret/Gramaj</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {describedIngredients.map((el) => {
                            return <div key={el.Id}>{el.Name}</div>;
                          })}
                        </td>
                        <td>
                          {itemPricesByQuantity.map((el) => {
                            return (
                              <div key={el.Id}>
                                {el.Quantity}ml-{el.Price}ron
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th scope="col">Specificații</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {itemSpecifications.map((item) => {
                            return (
                              <div key={item.Id}>{item.Specification}</div>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th scope="col">Rețetă</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{item.Recipe}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table className="mt-5">
                    <thead>
                      <tr>
                        <th scope="col">Descriere produs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {itemDescriptions.map((el) => {
                            return <div key={el.Id}>{el.Description}</div>;
                          })}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </ProductContainer>
              );
            })}
        </ProductListContainer>
      </Wrap>
    </>
  );
};

export default ProductList;
